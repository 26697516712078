.scale-me-on-hover {
  transition: all 0.2s;
  &:hover {
    transform: scale3d(1.02, 1.02, 1.02);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    // transform: translateY(-5px);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
}
