.campaign-thankyou-page {
  .custom-thank-you-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    .ads-img {
      object-fit: contain;
    }
    .description-box {
      .description {
        color: #143c4b !important;
      }
    }
    .thankyou-btn {
      padding: 5px 6rem;
    }
  }
}
