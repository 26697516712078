.location-list-modal {
  position: relative;
  border-radius: 8px;
  background: #fff;
  height: 500px;
  display: flex;
  flex-direction: column;

  .close-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -14px;
    right: -9px;
    cursor: pointer;
    color: #666;
  }
}
