.boost-campaign {
  .boost-page-title {
    line-height: 1.5;
    position: relative;
    padding-left: 30px;
    &::before {
      content: "";
      background-image: url("../../../public/img/icons/rocket.svg");
      width: 24px;
      height: 24px;
      background-size: 24px;
      background-position: left center;
      background-repeat: no-repeat;
      position: absolute;
      top: 7px;
      left: 0;
    }
    // img {
    //   position: absolute;
    //   left: -2px;
    //   top: 6px;
    // }
  }
  .table-heading {
    font-weight: 700;
    background: #f1f1f1;
    font-size: 0.9rem;
    border-radius: 7px;
  }
}
.confirm-location-item {
  .icon-img {
    width: 20px;
    height: 20px;
  }
  .label {
    font-size: 0.7rem;
    color: #516068;
  }
  .value {
    font-size: 0.9rem;
    font-weight: 600;
    padding-left: 24px;
    margin-top: 2px;
  }
}
.boost-confirm-total {
  .total-boost-budget {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
}

@media (min-width: 1700px) {
  .boost-confirm-total {
    .total-boost-budget {
      font-size: 1.2rem;
      // text-align: left;
      padding-left: 20px;
    }
  }
}
