/********************** Location Subscription ***************/
.location-subscription {
  min-height: 100vh;

  .rp-steps {
    margin-top: 0px;
    .MuiStepper-root {
      background-color: #f7f7f7;
      // margin-top: 3.5rem;
      margin-top: -30px;
    }
    .main-heading {
      letter-spacing: 0px;
      margin-bottom: 2rem;
    }

    .sub-heading {
      letter-spacing: 0px;
    }

    .note {
      font: normal normal normal 14px/19px "Open Sans" !important;
      letter-spacing: 0px;
      color: #67676a;
    }
  }

  main {
    .MuiStepper-root {
      background-color: #f7f7f7;
    }
  }

  .main-heading {
    letter-spacing: 0px;
    margin-bottom: 2rem;
  }

  .sub-heading {
    letter-spacing: 0px;
  }

  .note {
    font: normal normal normal 14px/19px "Open Sans" !important;
    letter-spacing: 0px;
    color: #67676a;
  }

  .page-description {
    font: normal normal normal 14px/19px "Open Sans" !important;
    letter-spacing: 0px;
    color: #67676a;
    width: 60%;
    margin: auto;
  }

  .subscription-packages {
    text-align: center;
    padding: 2rem 0.5rem;

    .package-card {
      position: relative;
      text-align: left;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 40px #00000016;
      border-radius: 10px;
      border: 0;

      &.recommended_package {
        border: 1px solid $primary-color;
      }

      .recommended {
        letter-spacing: 0px;
        padding: 0.5rem 0.5rem;
        background: #04a99933;
        color: $primary-color;
        border-radius: 5px;
        text-align: center;
        width: auto;
        font-size: 0.7rem;
        font-weight: 600;
      }

      .name {
        letter-spacing: 0px;
        color: $primary-color;
        font-weight: $fontWeight-Semi-Bold;
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #0f3447;
        margin-bottom: 0;
      }

      .val {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #0f3447;
      }

      .total {
        letter-spacing: 0px;
        vertical-align: middle;
      }

      .total-info {
        color: #c1c1c1;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1;
      }

      .media-fee {
        display: flex;
        align-items: center;
      }

      .subscription-fee {
        display: flex;
        align-items: center;
        margin-left: auto;
        .total-info {
          font-size: 0.85rem;
        }
      }

      .description {
        letter-spacing: 0px;
        color: #0f3447;
        font-size: 0.8rem;

        ul {
          padding-left: 18px;
          list-style: none;

          li {
            position: relative;
            margin-bottom: 10px;

            &::before {
              content: "";
              position: absolute;
              border-color: $text-color-main;
              border-style: solid;
              border-width: 0 0.2em 0.2em 0;
              height: 1em;
              top: 1.3em;
              left: -1.2em;
              margin-top: -1em;
              transform: rotate(45deg);
              width: 0.5em;
            }
          }
        }
      }
    }

    .contact {
      font-size: 16px;
      color: #67676a;
    }
  }

  .unsubscribed-locations {
    .locations {
      .location {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 40px #00000016;
        border-radius: $border-radius;
        margin-bottom: 7px;

        &.disabled {
          pointer-events: none;
          background: lightcyan !important;
        }

        .location-name {
          font-weight: 600;
        }

        .chip {
          height: 18px;
          font-size: 10px;
          font-weight: 600;
        }
      }

      .package-select {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #0000000a;
        border: 1px solid #f1f1f1;
        border-radius: 6px;
        padding: 5px 15px;
        font: normal normal 600 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #7a797e;

        option {
          &:hover {
            background: #219084 0% 0% no-repeat padding-box !important;
            box-shadow: 0px 3px 6px #0000000a;
            border: 1px solid #f1f1f1;
            border-radius: 6px;
          }
        }
      }
    }

    .package-selection-summary {
      position: sticky;
      top: 100px;
      box-shadow: 0px 8px 40px #00000016;
      border: 0;

      .heading {
        font: normal normal 600 19px/25px Montserrat;
        letter-spacing: 0px;
        color: #0f3447;
      }

      .selections {
        padding-right: 4px;
        max-height: 250px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: $text-color-main;
        }
      }

      .location-name {
        letter-spacing: 0px;
        font-weight: 700;
      }

      .package-info {
        letter-spacing: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .media-fee {
        letter-spacing: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .subscription-fee {
        letter-spacing: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        color: gray;
      }

      .total {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0px;
        color: #0f3447;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      .note {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0px;
        color: #0f3447;
      }
    }

    .location-note {
      background-color: #fff;
      padding: 0.5rem;
      font-weight: 600;

      a {
        color: $text-color-main;
        font-weight: 600;
      }
    }
  }

  .confirm-package-selection {
    .brand-match-card {
      background: $primary-color 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border-radius: $border-radius;
      border: 0 !important;
      color: $primary-contrast-color;

      .brand-match-detail {
        font-size: 0.8rem;
        letter-spacing: 0px;
        color: #ffffff;
        font-weight: $fontWeight-Semi-Bold;
      }
    }

    .prev-brand-match-card {
      background: #e5e5e5 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border-radius: $border-radius;
      border: 0 !important;
      color: #9d9d9d;

      .brand-match-detail {
        font-size: 0.8rem;
        letter-spacing: 0px;
        color: #9d9d9d;
        font-weight: $fontWeight-Semi-Bold;
      }
    }

    .location-summary {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border-radius: $border-radius;
      border: 0 !important;
    }

    .previous-location-data {
      background: #e5e5e5 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border-radius: $border-radius;
      border: 0 !important;

      .location-name {
        letter-spacing: 0px;
        font-weight: $fontWeight-Semi-Bold;
        color: rgb(129, 129, 129);
      }

      .tag {
        color: rgb(129, 129, 129);
        font-weight: 600;
      }

      .title {
        letter-spacing: 0px;
        color: #9d9d9d;
        font-size: 0.7rem;

        &.color-init {
          color: initial;
        }
      }

      .val {
        font-size: 0.8rem;
        letter-spacing: 0px;
        color: #9d9d9d;
        font-weight: $fontWeight-Semi-Bold;

        .currency {
          color: #9d9d9d;
        }
      }

      .total {
        font-size: 0.8rem;
        letter-spacing: 0px;
        color: rgb(129, 129, 129);
        font-weight: $fontWeight-Semi-Bold;
      }

      .subs {
        color: rgb(129, 129, 129);
        font-size: 0.8rem;
      }

      .brand-match-btn {
        background-color: #adadad;
        color: #737373;
        border: 1px solid #adadad;
        font-weight: 600;
      }
    }

    .tag {
      color: $primary-color;
      font-weight: 600;
    }

    .location-name {
      letter-spacing: 0px;
      font-weight: $fontWeight-Semi-Bold;
    }

    .title {
      letter-spacing: 0px;
      color: #516068;
      font-size: 0.7rem;

      &.color-init {
        color: initial;
      }
    }

    .val {
      font-size: 0.8rem;
      letter-spacing: 0px;
      color: #1b262c;
      font-weight: $fontWeight-Semi-Bold;

      .currency {
        color: $primary-color;
      }
    }

    .total {
      font-size: 0.8rem;
      letter-spacing: 0px;
      color: #1b262c;
      font-weight: $fontWeight-Semi-Bold;
    }

    .subs {
      color: #1b262c;
      font-size: 0.8rem;
    }

    .totals {
      background: #f7fffe 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border: 0;
      font-size: 0.9rem;

      .title {
        font-weight: $fontWeight-Semi-Bold;
        font-size: 0.9rem;
        letter-spacing: 0px;
        color: #7a797e;
      }

      .val {
        font-weight: $fontWeight-Semi-Bold;
        font-size: 0.9rem;
        letter-spacing: 0px;
        color: #485671;
      }

      .total_title {
        font-size: 0.9rem;
        font-weight: $fontWeight-Semi-Bold;
        letter-spacing: 0px;
        color: $primary-color;
      }

      .total_val {
        font-size: 0.9rem;
        font-weight: $fontWeight-Semi-Bold;
        letter-spacing: 0px;
        color: $primary-color;
      }
    }

    .note {
      font: normal normal 600 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #7a797e;
    }
  }

  .brand-match-tag-sg {
    top: 0;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .tag {
      background-color: $text-color-main;
      color: #fff;
      padding: 0.1rem 3rem;
      border-radius: 8px 8px 0px 0px;
    }
  }
  .brand-match-card {
    border: 1px solid #009688 !important;
  }

  .subscription-invoice {
    .table-server {
      .table-responsive {
        border: none !important;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .location-subscription {
    .page-description {
      width: 100%;
    }
  }
}

.payment-drawer {
  z-index: 9999 !important;
  .MuiDrawer-paper {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100%;
    height: 100vh;
  }
}

.card-header {
  position: relative;
  background-color: #fff;
  border: 0;
  border-radius: 10px 10px 0 0;
  padding: 1.25rem 1.25rem 0;
}

.table-title {
  position: relative;
  background-color: #fff;
  border: 0;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0px;
  color: #000000;
  font-size: 15px;
  padding-left: 10px;
  border-left: 4px solid $text-color-main;
}
