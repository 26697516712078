.pause-page-title {
  line-height: 1.5;
  position: relative;
  padding-left: 30px;

  &::before {
    content: "";
    background-image: url("../../../public/img/icons/setting-icon.svg");
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-position: left center;
    background-repeat: no-repeat;
    position: absolute;
    top: 3px;
    left: 0;
  }
}
.table-heading {
  font-weight: 700;
  background: #f1f1f1;
  font-size: 0.9rem;
  border-radius: 7px;
}
.pause-campaign-popover {
  &:hover {
    background: #f1f1f1;
  }
}
.status-change-confirmation {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .warning-icon {
    width: 90px;
    height: 90px;
    margin-bottom: 1.1rem;
    object-fit: contain;
  }

  .message-text {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.6;
    color: #000000;
  }

  .confirmation-title {
    font-weight: 600;
    font-size: 1.25rem;
    color: #1b262c;
    margin-bottom: 2rem;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
