.user-feedback {
  z-index: 99999 !important;
  .feedback-message {
    background-color: #efefef;
    border: 1px solid #dfdedc;
    border-radius: 10px;
  }
  .feedback-img {
    max-height: 100px;
  }
  .feedback-btn {
    padding-top: 8px;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 8px;
  }
}
