.customize-ads-container {
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  height: 100%;
  .ads-img {
    height: 9rem;
  }
  .description {
    color: #143c4b;
  }
  .customized-ads-btn {
    padding: 7px 6rem;
  }
}
