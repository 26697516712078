.dashboard {
  &.participant-dashboard {
    .post-card-campaign-available {
      .title {
        font-weight: 700;
      }

      .title-val {
        font-weight: 500;
      }

      .campaign-name {
        font-weight: 600;
      }

      .campaign-image {
        max-height: 150px;
        border-radius: 10px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .time-left {
        position: absolute;
        right: -12px;
        top: -25px;
        background: $primary-color;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        font-size: 7.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        color: $primary-contrast-color;
        box-shadow: 0px 6px 15px #04a99942;

        .time {
          font-size: 10px;
          font-weight: 600;
        }
      }

      .campaign-card {
        box-shadow: 2px 13px 50px #5160681a;
        border: 1px solid #e3e6f0;
        border-radius: 10px;
      }
    }
  }
}
