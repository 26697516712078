@import "./assets/css/utils.scss";

/** Font Family **/

$font-family: "Open Sans", sans-serif;
$font-family-Montserrat: "Montserrat", sans-serif;
$font-family-Avenir: "AvenirLTStd-Roman";
$font-family-Avenir-Black: "AvenirLTStd-Black";

/**Color**/
$primary-color: #04a999; // #218f83;
$secondary-color: #07304b;
$primary-contrast-color: #fff;
$secondary-contrast-color: #fff;

$dark-color: #143c4b;

$error-color: #ea3a3d;
$warning-color: #f9b959;
$success-color: #1ad598;

/** Button **/

$button-primary-color: $primary-color; // #229084;
$button-primary-text-color: $primary-contrast-color;
$box-shadow-color: #09aba733;
$border-radius: 7px;
$button-secondary-border-color: #09aba7;
$button-secondary-text-color: #09aba7;

$input-border-color: #d0d0d0;
$input-text-color: #55555c;

/** text color **/
$text-color: #143c4b;
$text-color-main: $primary-color; // #09aba7;
$text-color-main-dark: #548e8d;
$text-color-violet: #07304b;
$text-color-violet-light: #113f5a;
$text-color-Abbey: #50515c;
$text-color-black: #00000b;
$text-color-gray: #626262;
$text-color-gray-dark-light: #67676a;
$text-color-gray-dark-light1: #8a8a8b;
$text-color-gray-dark: #848484;
$text-color-gray-light: #9d9d9d;
$text-color-gray-light-light: #7c7c7c;
$text-color-gray-Gray32: #525252;
$text-color-gray-Gray43: #6e6e6e;

$text-active-color: #0aa82e;

/** Font Weight **/
$fontWeight-Light: 300;
$fontWeight-Regular: 400;
$fontWeight-Medium: 500;
$fontWeight-Semi-Bold: 600;
$fontWeight-Bold: 700;
$fontWeight-Extra-Bold: 800;

/** Mixin **/
@mixin font(
  $size: 16px,
  $font-color: initial,
  $fontWeight: 400,
  $line-height: initial,
  $font-font-family: $font-family
) {
  font-size: if($size, $size, 16px);
  font-weight: if($fontWeight, $fontWeight, 400);
  color: if($font-color, $font-color, initial);
  font-family: if($font-font-family, $font-font-family, $font-family);
  line-height: if($line-height, $line-height, initial);
  letter-spacing: 0;
}

/** generate font size mixin **/
@mixin fs {
  @for $i from 1 through 100 {
    .fs-#{$i} {
      font-size: #{$i}px !important;
    }
  }
}

/** Font Size **/
@include fs;

/** Gradient **/

$primary-gradient: transparent linear-gradient(90deg, #08a09b 0%, #47d8d3 100%)
  0% 0% no-repeat padding-box;
$secondary-gradient: transparent
  linear-gradient(90deg, #164456 0%, #3f8098 100%) 0% 0% no-repeat padding-box;
$primary-alt-gradient: transparent
  linear-gradient(90deg, #3282b8 0%, #5eabdf 100%) 0% 0% no-repeat padding-box;

/** Box Shadow **/
$box-shadow: 2px 13px 50px #5160681a;

/** General **/

body {
  font-family: $font-family;
  color: $text-color;

  overflow-y: scroll !important;
}

small {
  font-size: 65%;
}

.us-all {
  user-select: all;
}

::selection {
  color: $primary-contrast-color;
  background: $primary-color;
}

.rp-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  .clamp-3 {
    -webkit-line-clamp: 3;
  }

  .clamp-4 {
    -webkit-line-clamp: 4;
  }
}

.text-break {
  word-break: break-all;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

a {
  color: $text-color-main;

  // &:not(.MuiButton-root) {
  //   &:hover {
  //     color: $text-color-main;
  //   }
  // }
  &.MuiButton-root {
    &:hover {
      color: inherit;
    }
  }

  &.MuiButton-containedPrimary {
    &:hover {
      color: $primary-contrast-color;
    }
  }

  &.MuiButton-containedSecondary {
    &:hover {
      color: $secondary-contrast-color;
    }
  }
}

.disabled-link {
  pointer-events: none !important;
}

.error {
  @include font(12px, $error-color);
}

.error-orange {
  @include font(12px, #fd7e14);
}

.error-yellow {
  @include font(12px, #ffeb3b);
}

img {
  max-width: 100%;
}

.br-4 {
  border-radius: $border-radius !important;
}

.br-6 {
  border-radius: 6px !important;
}

.p-top {
  padding-top: 4.5rem !important;
}

.p-top-l {
  padding-top: 10rem !important;
}

.status-active {
  color: $text-active-color !important;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-col-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cursor-p {
  cursor: pointer;
}

.list-s-none {
  list-style: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

.fw-bb {
  font-weight: 900 !important;
}

.fw-b {
  font-weight: 700 !important;
}

.fw-sb {
  font-weight: 600 !important;
}

.fw-n {
  font-weight: normal !important;
}

/************ Scroll bar *******/

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f3f4f4 !important;
}

/******************Page************************/

.page {
  padding-top: 12px;
  padding-bottom: 2rem;
  min-height: calc(100vh - 105px);

  &.pt-s {
    padding-top: 4rem;
  }

  &.pt-m {
    padding-top: 5rem;
  }

  &.pt-l {
    padding-top: 7rem;
  }

  &.pt-xl {
    padding-top: 9rem;
  }

  &.pt-xxl {
    padding-top: 11rem;
  }
}

/********* Page header **************/
.page-header {
  padding: 0 0 3px;

  .page-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .page-title-icon {
      margin-right: 0.3rem;
    }

    .title {
      font-size: 12px;
      margin: 0;
      font-weight: $fontWeight-Semi-Bold;
      color: #222222;
    }
  }

  .welcome {
    font-size: 14px;
    font-weight: $fontWeight-Semi-Bold;
    letter-spacing: 0px;
    color: #0d2f44;
  }

  .user-name {
    font-size: 20px;
    font-weight: $fontWeight-Semi-Bold;
    letter-spacing: 0px;
    color: #0d2f44;
  }

  .last-updated {
    text-align: right;

    .title {
      font-size: 12px;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: #7a797e;
    }

    .date {
      font-size: 14px;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: #0d2f44;
    }
  }
}

/** fonts case **/
.upper {
  text-transform: uppercase;
}

.capital {
  text-transform: capitalize;
}

.lower {
  text-transform: lowercase;
}

.text-case-initial {
  text-transform: initial !important;
}

/** word break **/
.wb {
  word-break: break-word;
}

.link {
  color: $text-color-main !important;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600 !important;

  &:hover {
    color: $text-color-main !important;
    text-decoration: underline;
    text-decoration-thickness: 3px;
  }

  &.line {
    text-decoration: underline;
  }

  &.no-line {
    text-decoration: none !important;
  }

  &.link-w {
    font-weight: 700 !important;
  }
}

/** prevent any event on disabled link**/
.disabled-link {
  pointer-events: none;
}

.bottom-deco-line {
  position: relative;
  margin-bottom: 1.5rem;

  &::before {
    content: "";
    border-top: 2px solid $text-color-main;
    width: 50px;
    position: absolute;
    bottom: -7px;
    left: 0;
    color: $text-color-main;
  }
}

/****Buttons***/
button:focus {
  outline: none;
}

.button {
  max-width: 100%;
  width: 250px;
  height: 50px;
  border-radius: $border-radius;
  font-size: 16px;
  line-height: 19px;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.thin {
    height: 40px;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &.small {
    width: 108px;
  }

  &.mid-small {
    width: 222px;
  }

  &.medium {
    width: 285px;
  }

  &.large {
    width: 336px;
  }

  &.primary {
    background: $button-primary-color 0% 0% no-repeat padding-box;
    border: 1px solid $button-primary-color;
    box-shadow: 0px 3px 10px $box-shadow-color;
    opacity: 1;
    color: $button-primary-text-color;

    &:hover,
    &:active {
      background: darken($button-primary-color, 5%) 0% 0% no-repeat padding-box;
      background-color: darken($button-primary-color, 5%) !important;
      border-color: $button-primary-color !important;
    }

    &:disabled {
      background: rgba(34, 144, 132, 0.5) 0% 0% no-repeat padding-box;
      border: 1px solid rgba(34, 144, 132, 0.5);

      &:hover {
        background: rgba(34, 144, 132, 0.5) 0% 0% no-repeat padding-box;
        border: 1px solid rgba(34, 144, 132, 0.5);
      }
    }
  }

  &.secondary {
    border: 1px solid $button-secondary-border-color !important;
    background-color: rgba(255, 255, 255, 0.25);
    color: $button-secondary-text-color;

    &:hover,
    &:active {
      background: $button-primary-color 0% 0% no-repeat padding-box;
      background-color: $button-primary-color !important;
      border-color: $button-secondary-border-color !important;
      color: $button-primary-text-color;
    }
  }
}

/**Input**/
.input {
  height: 50px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  opacity: 1;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0;
  color: $input-text-color;

  &.small {
    height: 37px;
  }

  &.outline-border {
    border: 1px solid #09aba7;
  }
}

/** Material UI Text field **/
.MuiTextField-root {
  width: 100%;
}

// .MuiFormLabel-root {
//   color: $text-color-main !important;
// }
/******Number input********/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/***** MUI Icon Image *****/
img.material-icons.MuiIcon-root {
  object-fit: contain;
}

/** Headings **/

.heading01 {
  font-size: 77px;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0;
  color: #09aba7;
  text-transform: uppercase;
  opacity: 1;
}

.heading1 {
  font-size: 43px;
  letter-spacing: 0;
  opacity: 1;
  color: #07304b;

  &.white {
    color: #ffffff;
    line-height: 60px;
    font-weight: $fontWeight-Semi-Bold;
  }
}

.heading2 {
  font-size: 39px;
  letter-spacing: 0;
  color: #2e3244;
  opacity: 1;
}

.text42M_SemiBold09ABA7 {
  @include font(39px, #09aba7, $fontWeight-Semi-Bold);
}

.heading03 {
  // font: SemiBold 40px/69px Montserrat;
  @include font(30px, #50515c, $fontWeight-Semi-Bold);
  opacity: 1;
}

.heading3 {
  font-size: 37px;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0;
  color: #00000b;
  opacity: 0.67;
}

.text38M_SemiBold09ABA7 {
  // font: SemiBold 38px/69px Montserrat;
  @include font(35px, #09aba7, $fontWeight-Semi-Bold);
}

.text38M_SemiBold50515C {
  // font: SemiBold 38px/69px Montserrat;
  @include font(35px, #50515c, $fontWeight-Semi-Bold);
}

.text36M_SemiBold113F5A {
  // font: SemiBold 36px/69px Montserrat;
  @include font(33px, #113f5a, $fontWeight-Semi-Bold);
}

.heading4 {
  font-size: 28px;
  line-height: 69px;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0;
  color: #113f5a;
  opacity: 1;

  &.upper {
    text-transform: uppercase;
  }
}

.heading05 {
  font-size: 27px;
  line-height: 24px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.heading050 {
  font-size: 24px;
  line-height: normal;
  letter-spacing: 0;
  color: #ffffff;
}

.heading051 {
  // font: Bold 29px/69px Montserrat;
  @include font(29px, #626262, $fontWeight-Bold, 69px);
  opacity: 0.24;
}

.heading5 {
  font-size: 25px;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0;
  opacity: 1;

  &.white {
    color: #ffffff;
  }

  &.upper {
    text-transform: uppercase;
  }
}

.text28M_SemiBolFFFFFF {
  //font: SemiBold 28px/69px Montserrat;
  @include font(25px, #ffffff, $fontWeight-Semi-Bold);
}

.text21M_SemiBolFFFFFF {
  //font: SemiBold 28px/69px Montserrat;
  @include font(21px, #ffffff, $fontWeight-Semi-Bold);
}

.text25M_Bold337ca2 {
  //font: Bold 25px/30px Montserrat;
  @include font(22px, #337ca2, $fontWeight-Bold, 30px);
}

.heading6 {
  font-size: 21px;
  letter-spacing: 0;
  color: #9d9d9d;
  opacity: 1;
}

.text24M_Bold00000b {
  //font: Bold 24px/69px Montserrat;
  @include font(17px, lighten(#00000b, 10%), $fontWeight-Bold);
}

.text2469M_Bold00000b14 {
  //font: Bold 24px/69px Montserrat;
  @include font(14px, lighten(#00000b, 10%), $fontWeight-Bold, 69px);
}

.text24M_Bold00000b14 {
  //font: Bold 24px/69px Montserrat;
  @include font(14px, lighten(#00000b, 10%), $fontWeight-Bold);
}

.text24M_Med00000b {
  //font: Medium 24px/69px Montserrat;
  @include font(17px, lighten(#00000b, 10%));
}

.text2440M_Med00000b {
  //font: Medium 24px/69px Montserrat;
  @include font(17px, lighten(#00000b, 10%), 40px);
}

.text24M_Bold83898E {
  //font: Bold 24px/69px Montserrat;
  @include font(17px, lighten(#83898e, 10%), $fontWeight-Bold);
}

.heading61 {
  // font: SemiBold 24px/69px Montserrat;
  @include font(17px, #00000b, $fontWeight-Semi-Bold, 69px);
  opacity: 0.67;
}

.text24M_SemiBold00000b {
  //font: SemiBold 24px/69px Montserrat;
  @include font(17px, #00000b, $fontWeight-Semi-Bold);
}

.text22M_Med00000b {
  //font: Medium 22px/69px Montserrat;
  @include font(19px, lighten(#00000b, 10%));
}

.text22M_SemiBold525252 {
  //font: Medium 22px/69px Montserrat;
  @include font(19px, #525252, $fontWeight-Semi-Bold);
}

.text22M_SemiBold0AA82E {
  //font: Medium 22px/69px Montserrat;
  @include font(19px, #0aa82e, $fontWeight-Semi-Bold);
}

.text20M_SemiBold548E8D {
  //font: Semi Bold 20px/27px Montserrat;
  @include font(17px, #548e8d, $fontWeight-Semi-Bold);
}

.heading7 {
  font-size: 17px;
  line-height: 27px;
  font-weight: $fontWeight-Bold;
  letter-spacing: 0;
  color: #548e8d;
  opacity: 1;

  &.upper {
    text-transform: uppercase;
  }
}

.heading8 {
  font-size: 17px;
  letter-spacing: 0;
  opacity: 1;

  &.white {
    color: #ffffff;
  }
}

.heading9 {
  font-size: 15px;
  font-weight: $fontWeight-Bold;
  letter-spacing: 0;
  color: #848484;
  opacity: 1;

  a {
    color: $text-color-main;
  }
}

.heading09 {
  font-size: 15px;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0;
  color: #00000b;

  //opacity: 0.67;
  a {
    color: $text-color-main;
  }

  &.gray-color {
    color: $text-color-gray;
  }

  &.gray-color-32 {
    color: $text-color-gray-Gray32;
  }

  &.main-color {
    color: $text-color-main;
  }

  &.Abbey {
    color: $text-color-Abbey;
  }
}

.text18M_SemiBold83898E {
  // font: SemiBold 18px/69px Montserrat;
  @include font(15px, #83898e, $fontWeight-Semi-Bold);
}

.text18M_SemiBold09ABA7 {
  // font: SemiBold 18px/69px Montserrat;
  @include font(15px, #09aba7, $fontWeight-Semi-Bold);
}

.text18M_SemiBold00000B {
  // font: SemiBold 18px/69px Montserrat;
  @include font(15px, #00000b, $fontWeight-Semi-Bold);
}

.heading10 {
  font-size: 15px;
  font-weight: $fontWeight-Bold;
  letter-spacing: 0;
  color: #00000b;
  opacity: 0.67;
}

.heading11 {
  font-size: 15px;
  letter-spacing: 0;
  color: #67676a;
  opacity: 1;
}

.heading12 {
  font-size: 15px;
  letter-spacing: 0;
  color: #626262;
  opacity: 1;

  &.black-color {
    color: $text-color-black;
  }
}

.heading013 {
  font-size: 14.5px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.text18M_Reg00000B {
  // font: Regular 18px/24px Montserrat;
  @include font(15px, #00000b);
}

.text18M_Med55555c {
  // font: Medium 18px/22px Montserrat;
  @include font(15px, #55555c, null, 22px);
}

.text18M_Med09aba7 {
  // font: Medium 18px/22px Montserrat;
  @include font(15px, #09aba7, null, 22px);
}

.text1822P_SemiBold6E6E6E {
  // font: Semi bold 18px/22px Open Sans;
  @include font(15px, #6e6e6e, $fontWeight-Semi-Bold, 22px);
}

.text1822P_Reg83898E {
  // font: Regular 18px/22px Open Sans;
  @include font(15px, #83898e, null, 22px);
}

.text1822P_SemiBold83898E {
  // font: Regular 18px/22px Open Sans;
  @include font(15px, #83898e, null, 22px);
}

.heading13 {
  font-size: 13px;
  letter-spacing: 0;
  color: #7c7c7c;
  opacity: 1;
}

.heading014 {
  font-size: 13px;
  letter-spacing: 0;
  color: #ffffff;
}

.heading14 {
  font-size: 13px;
  font-weight: $fontWeight-Semi-Bold;
  letter-spacing: 0;
  color: #9d9d9d;
  opacity: 1;

  &.main-color {
    color: $text-color-main;
  }

  &.gray-color {
    color: $text-color-gray;
  }
}

.text16P_SemiBold09ABA7 {
  // font: Semibold 16px/22px Open Sans;
  @include font(13px, #09aba7, $fontWeight-Semi-Bold, 19px);
}

.text16P_SemiBold626262 {
  // font: Semibold 16px/22px Open Sans;
  @include font(13px, #626262, $fontWeight-Semi-Bold, 19px);
}

.text16P_Reg626262 {
  // font: Regular 16px/22px Open Sans;
  @include font(13px, #626262, null, 19px);
}

.text16P_Bold548E8D {
  // font: Bold 16px/22px Open Sans;
  @include font(13px, #548e8d, $fontWeight-Bold, 19px);
}

.text16M_Med09aba7 {
  // font: Medium 16px/19px Montserrat;
  @include font(13px, #09aba7, null, 19px);
}

.text14P_SemiBold83898E {
  // font: Semibold 14px/69px Open Sans;
  @include font(11px, #83898e, $fontWeight-Semi-Bold);
}

.text14P_Reg42A32E {
  // font: Semibold 14px/69px Open Sans;
  @include font(11px, #42a32e);
}

.text14P_SemiBold83898E {
  // font: SemiBold 14px/69px Montserrat;s;
  @include font(11px, #83898e, $fontWeight-Semi-Bold);
}

.text14P_SemiBold09ABA7 {
  // font: Semibold 14px/19px Open Sans;
  @include font(14px, #09aba7, $fontWeight-Semi-Bold);
}

.text14P_SemiBold83898E {
  // font: Semibold 14px/19px Open Sans;
  @include font(14px, #83898e, $fontWeight-Semi-Bold);
}

/** paragraph **/
p {
  letter-spacing: 0;
  opacity: 1;

  &.white {
    color: #ffffff;
  }
}

/** Table **/
table {
  background: #ffffff 0% 0% no-repeat padding-box !important;

  border-radius: 6px !important;

  thead {
    background: #eff2ff 0% 0% no-repeat padding-box;

    th {
      @include font(13px, #548e8d, $fontWeight-Bold, 22px);
      vertical-align: middle !important;
    }
  }
}

/** login-page **/

.login-page {
  height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
}

/** Auth Left View **/

.auth-left-view {
  background-attachment: initial !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-origin: padding-box !important;
  background-clip: padding-box !important;
  background-color: transparent !important;
  opacity: 0.96 !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
  padding-right: 5rem;

  .logo-image-box {
    min-height: 200px;
  }

  .logo-image-bg {
    position: absolute;
    bottom: 0;
    left: -30px;
    width: 200px;
    height: 200px;
  }

  .logo-image {
    position: absolute;
    bottom: -11px;
    left: 0;
  }
}

/** Profile Drop-down  **/
.user-profile {
  max-width: 230px;
  height: 50px;
  border-radius: $border-radius;

  .material-icons {
    font-size: small;
  }

  .user-name {
    font-weight: $fontWeight-Bold;
    letter-spacing: 0.03px;
    color: #102d39;
    font-size: 12px;
  }

  .user-email {
    font-size: 8px;
    color: #62757d;
  }

  .user-logout {
    font-size: 10px;
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  button {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .user-detail {
    max-width: 70%;

    .name {
      max-width: 200px;
    }

    .user_id {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      text-align: left;
      max-width: 100%;
    }
  }

  img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    opacity: 1;
    object-fit: cover;
  }

  .img-icon {
    width: 20px;
    height: 20px;
  }

  .dropdown-divider {
    border-color: #eee;
  }

  .dropdown-menu {
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 10px 20px #0000000d;
    box-shadow: 0px 6px 30px #0000001f;
    border-radius: 3px;
    border: 0;
    min-width: 240px;

    .drop-down-user-name {
      width: 155px;
      padding: 0.25rem 1.5rem;
    }

    .dropdown-divider {
      margin: 0 auto;
    }

    .dropdown-item {
      padding-top: 10px;
      padding-bottom: 10px;

      &::after {
        content: "chevron_right";
        position: absolute;
        right: 6px;
        font-family: "Material Icons";
      }

      &:first-child::after {
        content: "";
      }
    }
  }

  .material-icons {
    vertical-align: middle;
  }
}

/***************Header Brand Logo Drop down**********************/
.header-brand-logo-drop-down {
  button {
    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-toggle {
    &:focus {
      box-shadow: none;
    }

    &::after {
      border-top-color: #000;
    }
  }

  .dropdown-menu {
    max-width: 200px;
    max-height: 300px;
    overflow-y: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 30px #0000001f;
    border: 0;

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;

      &.active {
        font-weight: 600;
      }
    }
  }
}

/** Bootstrap Drop Down **/
.dropdown-toggle {
  &::after {
    border-top: 0.3em solid #fff;
  }
}

.dropdown-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #0000001a;
  border-radius: $border-radius;
  opacity: 1;
  z-index: 1040;
}

.dropdown-item {
  color: $text-color-gray-dark-light1;
  font-size: 12px;
  letter-spacing: 0;

  &:hover {
    background-color: lighten(#e4f6f5, 3%);
  }

  &.active {
    text-decoration: none;
    // background-color: $text-color-main;
    background-color: darken(#0000001f, 10%);
    background-color: #e4f6f5;
    color: inherit;
  }

  &:active {
    text-decoration: none;
    // background-color: $text-color-main;
    background-color: darken(#0000001f, 10%);
    color: inherit;
  }
}

/** Home Page **/
.home-page {
  // background: linear-gradient(to bottom, transparent 670px, #fff 0),
  //   url(./assets/img/home_header_bg.svg);
  background: linear-gradient(to bottom, transparent 670px, #fff 0),
    url(./assets/img/homebg.png);

  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 10% 0%;

  // background-position: bottom;
  // background-blend-mode: multiply;
  // background-position: 38% 100% !important;
  // background-position: 175px !important;
  .header {
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 40px #77777719;
    opacity: 1;
    z-index: 99;

    .brand-image {
      max-width: 150px;
      max-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 150px;
        max-height: 60px;
        object-fit: contain;
        // filter: drop-shadow(0 0 0.1rem #04a999);
      }
    }
  }

  .content {
    .main-heading {
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #0f3447;
    }

    p {
      letter-spacing: 0.4px;
    }
  }

  .how-it-works-container {
    margin-top: 0;
    padding-top: 0;
  }
}

/** Headers **/

/** Main Header **/
.main-header {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 40px #77777719;
  opacity: 1;

  .brand-image {
    width: 165px;
    width: auto;
    max-width: 165px;
    max-height: 55px;
    // height: 55px;

    img {
      width: 100%;
      width: auto;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 40px;
      // filter: drop-shadow(0 0 0.1rem #04a999);
    }
  }
}

/** Sub Header **/
.sub-header {
  background: #0e3044 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  top: 78px;
  z-index: 1020;

  .user-details {
    display: flex;
    align-items: center;

    .user-profile-image {
      width: 55px;
      height: 55px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      max-width: 270px;
    }
  }

  .date-time {
    min-width: 230px;
    max-width: 230px;
  }
}

/** Back Link **/
.back-link {
  text-decoration: underline !important;
  color: $text-color-main !important;
  cursor: pointer;
  text-transform: none !important;
}

/********* How it Works **************/
.how-it-works {
  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #283f6724;
    border-radius: 20px;
    border: 0;

    .heading {
      font: normal normal bold 35px/49px Montserrat;
      letter-spacing: 0px;
      color: #143c4b;
    }

    .sub-heading {
      font: normal normal bold 20px/24px Montserrat;
      letter-spacing: 0px;
      color: #143c4b;
    }

    p {
      font: normal normal normal 14px/19px "Open Sans";
      letter-spacing: 0px;
      color: #67676a;
    }

    .count {
      font: normal normal 600 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #04a999;
      background: rgba(30, 202, 184, 0.15) !important;
      border-radius: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  .video-section {
    position: relative;

    .video-heading {
      font: normal normal bold 35px/49px Montserrat;
      letter-spacing: 0px;
      color: #143c4b;
    }

    .sub-video-heading {
      font: normal normal bold 16px/22px Open Sans;
      letter-spacing: 0px;
      color: $primary-color !important;
    }
  }
}

/** Learn More Page **/
.learn-more {
  .page-title {
    font-size: 24px;
    font-weight: $fontWeight-Semi-Bold;
    letter-spacing: 0px;
    color: #143c4b;
  }

  .title {
    font-size: 12px;
    font-weight: $fontWeight-Semi-Bold;
    letter-spacing: 0px;
    color: #7a797e;
  }

  .title-val {
    font-size: 20px;
    font-weight: $fontWeight-Semi-Bold;
    letter-spacing: 0px;
  }

  .faq-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 50px #5160681a;
    border-radius: 10px;
    border: 0;
  }

  .faq-title {
    font-size: 20px;
    letter-spacing: 0px;

    span {
      color: $button-primary-color;
    }
  }

  .video-section {
    .video-heading {
      font-size: 28px;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: #143c4b;
      text-align: center;
    }

    .sub-video-heading {
      font-size: 12px;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: $primary-color;
      text-align: center;
    }
  }

  .campaign-channels {
    color: $text-color-gray;
  }

  .campaign-objective {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 50px #5160681a;
    border-radius: 10px;
    border: 0;
    padding: 1rem;
  }
}

/** FAQ **/
.faq {
  .faq-question {
    font-size: 14px;
    letter-spacing: 0.36px;
  }

  .faq-answer {
    font-size: 12px;
    font-weight: $fontWeight-Regular;
    letter-spacing: 0.57px;
  }

  .MuiExpansionPanel-root {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #91919127;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    margin: 0.5rem auto;

    &::before {
      background-color: transparent;
    }
  }
}

/** Thank you page**/
.thank-you-page {
  // min-height: calc(100vh - 80px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;

  img {
    width: 200px;
    height: 150px;
    object-fit: cover;
  }
}

/** Profile **/
.profile {
  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #91919127;
    border: 1px solid #eaeaea;
    border-radius: 6px;
  }
  .profile-card-border {
    border-radius: 16px !important;
  }
  label.upload-btn {
    background: $primary-color;
    color: $primary-contrast-color;
    box-shadow: 0px 3px 20px #04a9992e;
    border: 1px solid #0aaba7;
    border-radius: 4px;
    position: absolute;
    padding: 5px 1rem;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: initial;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    margin: auto;
    bottom: 20px;
    width: 100px;

    .MuiSvgIcon-root {
      font-size: 10px;
      margin-right: 5px;
    }
  }

  .cancel-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-in-out;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: auto;

    .close-icon {
      color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
      //display: none;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);

      .close-icon {
        display: block;
      }
    }
  }

  .edit {
    // font: Medium 18px/69px Montserrat;
    @include font(18px, #626262);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-brands {
    box-shadow: 0px 8px 40px #00000016;
    border: 0;

    .card-header {
      background: url(./assets/img/profile_header_BG_green1.png);
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      min-height: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .heading {
        font: normal normal 600 26px/22px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        margin: 0;
      }

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #fff;
        min-width: 80px;
        min-height: 80px;

        .logo-title {
          font: normal normal 600 16px/22px Montserrat;
          letter-spacing: 0px;
          color: #ffffff;
        }

        .brand-logo {
          width: 80px;
          height: 80px;
          object-fit: contain;
          border-radius: 5px;
          background-color: #fff;
        }
      }
    }

    .brands {
      .brand {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 2px 13px 50px #5160681a;
        border: 1px solid #e3e6f0;
        border-radius: 5px;

        .brand-img {
          width: 90px;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          margin-right: 0.5rem;
          padding: 2px;

          img {
            max-height: 100%;
            // filter: drop-shadow(0 0 0.1rem $text-color-main);
          }
        }

        .brand-name {
          font: normal normal 600 16px/22px Montserrat;
          letter-spacing: 0px;
          color: #0f3447;
          margin-bottom: 1rem;
        }

        .subscription-status {
          font: normal normal 600 12px/17px Open Sans;
          letter-spacing: 0px;

          .status {
            border-radius: 5px;
            padding: 0.2rem 0rem;
            font-size: 12px !important;

            &.subscribed {
              background: #d3fad4 0% 0% no-repeat padding-box;
              border: 1px solid #96ff9f;
              color: #1ac922;
            }

            &.unsubscribed {
              background: #ffe3e3 0% 0% no-repeat padding-box;
              border: 1px solid #ffbdbd;
              color: #fd3737;
            }
          }
        }

        .subscription-dates {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e3e6f0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 1rem;
          padding: 0.8rem;

          .title {
            color: #516068;
            letter-spacing: 0px;
            font-size: 0.7rem;
          }

          .value {
            letter-spacing: 0px;
            color: #1b262c;
            margin-bottom: 0.5rem;
            font-size: 0.8rem;
            font-weight: $fontWeight-Semi-Bold;
          }
        }

        .subscription-detail {
          height: 100%;
          border-left: 1px solid #eee;
          padding: 1rem;
          max-height: 190px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: #182c8f;
          }

          &::-webkit-scrollbar-thumb {
            background: $text-color-main;
          }

          ul {
            li {
              &::marker {
                // content: "✔";
                content: "✓";
              }
            }
          }
        }
      }
    }
  }

  .profile-card {
    position: relative;
    box-shadow: 0px 8px 40px #00000016;
    border: 0;

    .profile-card-header {
      background: url(./assets/img/profile_header_BG_green.png);
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      min-height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .profile-image-box {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: -120px;
      min-height: 155px;

      .profile-image-container {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin: auto;
        position: relative;
        background-color: #f8f9fd;
      }

      .profile-image {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin: auto;
        object-fit: cover;
      }
    }

    .profile-content {
      margin-top: 4rem;
    }

    .brand-logo-title {
      font: normal normal 600 16px/22px Montserrat;
      letter-spacing: 0px;
      color: #0f3447;
    }

    .brand-logo-box {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font: normal normal 600 20px/22px Montserrat;
      letter-spacing: 0px;
      color: #0f3447;
      margin: 2rem auto;
      width: 100%;

      .brand-logo-container {
        max-width: 100%;
        max-height: 100%;
        width: 155px;
        height: 155px;
        margin: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px dashed #bcbcbc;
        border-radius: 5px;

        .brand-logo {
          background-color: #f8f9fd;
          max-width: 140px;
          max-height: 140px;
          object-fit: contain;
        }

        .cancel-icon-box {
          border-radius: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .action-btn {
      margin: 0.5rem auto;
    }
    .profile-card-start-icon {
      width: 18px;
      height: 18px;
    }
    .action-btn:hover .profile-card-start-icon {
      filter: brightness(0) invert(1) !important;
      color: $text-color-main !important;
      text-decoration: underline;
      text-decoration-thickness: 3px;
    }

    .user-name {
      font-weight: $fontWeight-Semi-Bold;
      font-size: 1.2rem;
      letter-spacing: 0px;
      color: #0f3447;
      user-select: all;
    }

    .user-email {
      font-size: 0.7rem;
      letter-spacing: 0px;
      color: #516068;
      display: flex;
      text-align: left;
      word-break: break-all;
      user-select: all;

      .icon {
        color: #bbb;
        margin-top: 0.1rem;
        margin-right: 5px;
      }
    }

    .user-phone {
      font-size: 0.7rem;
      letter-spacing: 0px;
      color: #516068;
      display: flex;
      text-align: left;
      word-break: break-all;
      user-select: all;

      .icon {
        color: #bbb;
        margin-top: 0.1rem;
        margin-right: 5px;
      }
    }

    .user-id-box {
      background: #e5f6f2 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 5px;
      width: 150px;
      max-width: 100%;
      margin: auto;

      .id {
        border: 1px dashed #9dd1cb;
        border-radius: 5px;
        color: $text-color-main;
        font-weight: 600;
        font-size: 12px;
        padding: 5px;
        text-align: center;
      }
    }

    .ds-link {
      height: auto;
      padding: 0.5rem;
      max-width: 190px;
      text-align: center;
      font-size: 14px;
    }
  }

  .brand-locations {
    box-shadow: 0px 8px 40px #00000016;
    border: 0;
    height: 100%;

    .heading {
      font-size: 14px;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: #0d2f44;
      margin-bottom: 3px;
    }

    .brand-name {
      letter-spacing: 0px;
      color: #516068;
      line-height: 1;
    }

    .subscription-button {
      font-size: 11px;
    }

    .locations {
      list-style: none;
      padding-left: 0;

      .location {
        border-radius: 16px;
        box-shadow: none;
        margin-bottom: 5px;

        &::before {
          background-color: transparent;
        }

        .default-target-radius {
          color: #f8e252;
        }
        @media (min-width: 768px) {
          .location-subscription-border {
            border-right: 1px solid #dee2e6 !important;
            border-bottom: none !important;
          }
        }

        .MuiAccordionSummary-root {
          background: #376778 0% 0% no-repeat padding-box;
          color: #fff;
          border-radius: 16px;

          &.Mui-expanded {
            border-radius: 16px 16px 0 0;
          }

          .MuiAccordionSummary-content {
            margin: 0;
          }

          .MuiAccordionSummary-expandIcon {
            .MuiIconButton-label {
              color: #fff;

              svg {
                font-size: 1.2rem;
              }
            }
          }
        }

        .MuiCollapse-root {
          &.MuiCollapse-entered {
            border: 1px solid #c1c1c1;
            border-radius: 0 0 16px 16px;
          }
        }

        .location-name-box {
          display: flex;
          width: 100%;
        }

        .location-name {
          font-size: 0.8rem;
          letter-spacing: 0px;
        }

        .location-status {
          height: 16px;
          font-size: 10px;
          margin-right: 5px;
        }

        .location-subscription-detail {
          width: 100%;

          .package-detail {
            .heading {
              font-size: 12px;
              font-weight: $fontWeight-Bold;
              letter-spacing: 0px;
              color: #229084;
              margin-bottom: 10px;
            }

            .title {
              font-size: 12px;
              font-weight: $fontWeight-Regular;
              letter-spacing: 0px;
              color: #3d5e6a;
            }

            .value {
              font-size: 12px;
              font-weight: $fontWeight-Semi-Bold;
              letter-spacing: 0px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .notice-box {
    box-shadow: 0px 8px 40px #00000016;
    border: 0;

    .notice {
      // background: #e5f6f2 0% 0% no-repeat padding-box;
      // border: 1px dashed #9dd1cb;
      border-radius: 5px;
      font: normal normal 600 12px/15px Open Sans;
      color: #7a797e;
      // padding: 10px;

      a {
        color: $text-color-main;
      }
    }
  }

  .admin-brand {
    position: relative;
    margin: 5rem auto auto;
    width: 250px;
    height: 145px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 6px;
    border: 1px solid #eee;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  /** Edit **/
  .user-name {
    .MuiInput-input {
      // font: SemiBold 40px/69px Montserrat;
      @include font(18px, #50515c, $fontWeight-Semi-Bold);
      opacity: 1;
    }
  }
}

/** Join Campaign Confirmation **/

.join-campaign-confirmation-sub-header {
  background: #eee 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 0;
  right: 0;
  top: -30px;
  z-index: 102;
  height: 50px;
  margin-left: -15px;
  margin-right: -15px;
}

.confirmation {
  .confirm-page {
    margin-top: -40px;
  }
}

.join-campaign-confirmation {
  .page-title {
    font-size: 20px;
    letter-spacing: 0px;
    color: #0f3447;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .title {
    font-size: 0.7rem;
    letter-spacing: 0px;
    color: #516068;
  }

  .title-val {
    font-size: 0.8rem;
    font-weight: $fontWeight-Semi-Bold;
    letter-spacing: 0px;

    .currency {
      color: $primary-color;
    }
  }

  .totals {
    margin-top: 1rem;
    text-align: right;

    .card {
      background: #f7fffe 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border: 0;
    }

    .title-other {
      font-size: 0.8rem;
      letter-spacing: 0px;
    }

    .amount-other {
      font-size: 0.8rem;
      letter-spacing: 0px;
    }

    .title {
      font-weight: $fontWeight-Semi-Bold;
      font-size: 0.9rem;
      color: initial;
    }

    .amount {
      font-size: 0.9rem;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
    }

    .title-to-pay {
      font-size: 1rem;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: $primary-color;
    }

    .amount-to-pay {
      font-size: 1rem;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: $primary-color;
    }
  }

  .note {
    font-size: 12px;
    font-weight: $fontWeight-Semi-Bold;
    letter-spacing: 0px;
    color: #7a797e;
  }

  // margin: 1rem auto;

  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 50px #5160681a;
    border: 0;
    border-radius: 6px;
  }

  .info-box {
    background: #eff2ff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e4ed;
    border-radius: 6px;

    .heading {
      height: 35px;
      margin-top: 1rem;
    }
  }

  table {
    border: 0;
    box-shadow: none !important;

    thead {
      th {
        text-transform: none;
      }
    }

    tbody {
      td {
        background: #eff2ff 0% 0% no-repeat padding-box;
      }
    }
  }
}

/** Join Campaign **/
.join-campaign {
  .header {
    background: #eff2ff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000039;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 80px;
    z-index: 1029;

    .actions {
      min-width: 250px;
    }
  }

  .budget-planing {
    margin-top: 6rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #91919127;
    border: 1px solid #eaeaea;
    border-radius: 6px;

    .MuiInputBase-root.Mui-disabled {
      color: initial;
      background-color: #e9ecef;
    }

    .MuiInputAdornment-positionEnd {
      display: none;
    }
  }

  .budget {
    background: url(./assets//img/BudgetBG.png) #eff2ff 0 0 no-repeat
      padding-box;
    border-radius: 6px 6px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 167px;
  }

  .specialties {
    .creative {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      margin-bottom: 1.5rem;

      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .img-caption {
          text-align: center;
          max-width: 80%;
        }
      }

      img {
        width: 90%;
        max-height: 100%;
        cursor: pointer;
      }

      input[type="radio"] {
        display: none;

        &:checked {
          & + img {
            border: 1px dashed green;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

            & + .img-caption {
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  .budget_currency_input {
    &::placeholder {
      font-size: 10px;
    }
  }
}

.join-campaign-page {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding-top: 47px;

  .join-campaign-sub-header {
    // position: fixed;
    margin-top: -58px;
    /* width: 100%; */
    margin-left: -8px;
    margin-right: -8px;
    background: #eeeeee 0% 0% no-repeat padding-box;
    // box-shadow: 0px 3px 6px #00000012;
    z-index: 10;

    .button {
      max-height: 40px;
    }
  }

  .join-postcard-campaign-sub-header {
    // position: fixed;
    margin-top: -58px;
    /* width: 100%; */
    margin-left: -8px;
    margin-right: -8px;

    background: #eeeeee 0% 0% no-repeat padding-box;
    // box-shadow: 0px 3px 6px #00000012;
    z-index: 10;

    .button {
      max-height: 40px;
    }
  }

  .join-campaign-form {
    .expand-all {
      font: normal normal medium 16px/19px Montserrat;
      letter-spacing: 0px;
      color: #229084;
      text-transform: none;
    }

    // .join-campaign-sub-header {
    //   // position: fixed;
    //   margin-top: -20px;
    //   width: 100%;
    //   // top: 73px;
    //   background: #eeeeee 0% 0% no-repeat padding-box;
    //   // box-shadow: 0px 3px 6px #00000012;
    //   z-index: 10;

    //   .button {
    //     max-height: 40px;
    //   }
    // }

    .heading {
      font: normal normal 600 20px/22px Montserrat;
      letter-spacing: 0px;
      color: #0f3447;
    }

    .locations {
      .location {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: $box-shadow;
        border: 0;
        border-radius: $border-radius;
        margin-bottom: 0.5rem;

        &::before {
          background-color: transparent;
        }

        .location-details {
          .heading {
            letter-spacing: 0px;
            color: #222222;
            margin: 1rem auto;
            font-size: 1rem;
          }
        }

        .location-budget-input-container {
          background: #f7fffe 0% 0% no-repeat padding-box;
          border-left: 1px solid #ccc;
        }

        .location-budget {
          label {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #0f3447;
          }

          .MuiInputBase-input {
            font-weight: $fontWeight-Semi-Bold;
            font-size: 12px;
            letter-spacing: 0px;
            color: #0f3447;
            background: #fff;
          }

          .Mui-disabled {
            background: inherit;
          }
        }

        .location-date {
          label {
            font-weight: $fontWeight-Semi-Bold;
            font-size: 12px;
            letter-spacing: 0px;
            color: #0f3447 !important;
          }

          .MuiInputBase-input {
            font-size: 12px;
            letter-spacing: 0px;
            color: #fff;
          }

          .rp-date-input-box {
            position: relative;

            .rp-date-picker {
              &:has(.Mui-disabled) {
                background-color: #c1c1c1;
                box-shadow: none;
              }

              input {
                font-weight: 600;

                &::placeholder {
                  opacity: 1;
                }

                &.Mui-disabled {
                  &::placeholder {
                    color: grey !important;
                  }
                }
              }
            }

            fieldset {
              border: 0;
            }

            .calendar-icon {
              position: absolute;
              right: 7px;
              top: 7px;
              cursor: pointer;
            }
          }
        }
      }

      .location-header {
        .MuiAccordionSummary-content {
          // margin: 0;
        }

        .location-header-content {
          padding: 0.5rem 0 0.5rem 0.5rem;

          .location-name {
            letter-spacing: 0px;
            color: #0f3447;
          }

          .location-meta {
            font: normal normal 600 12px/20px Open Sans;
            letter-spacing: 0px;
            color: #0f3447;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            span {
              color: $text-color-main;
            }
          }

          &.unfilled {
            .location-name {
              font-weight: 500;
              font-size: 16px;
            }
          }

          &.partially-filled {
            .MuiSvgIcon-colorError {
              color: #fa5b5b;
            }
          }
        }
      }

      .creatives {
        .card {
          border: 1px solid #c1c1c1;
        }

        .creative-options {
          max-height: 235px;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 0px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #239084;
            outline: 1px solid #219184;
          }

          .heading {
            font-weight: 600;
            letter-spacing: 0px;
            color: #222222;
          }

          .selected {
            .checked-icon {
              fill: #fff;
            }
          }

          .creative-option-label {
            font-size: 13px;
            font-weight: $fontWeight-Regular;
            letter-spacing: 0px;
          }
        }
      }

      .total-budget-box {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: $box-shadow;
        border: 0;

        .total-budget {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;

          .total {
            background: #ffffff 0% 0% no-repeat padding-box;
            // border: 2px solid #2b9084;
            border-radius: 5px;
            padding: 10px;
            min-width: 150px;
            background: #ebeeff 0% 0% no-repeat padding-box;
            color: #239084;
            font-size: 20px;
            // border-style: dashed;
          }
        }
      }

      .actions {
        font: normal normal medium 12px/18px Montserrat;
        letter-spacing: 0px;
        color: #8f8f8f;
      }

      .link {
        font: normal normal medium 12px/18px Montserrat;
        letter-spacing: 0px;
        color: #229084;
        cursor: pointer;
      }
    }
  }

  .un-subscribed-locations {
    .card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 10px #d1d5df33;
      border: 0;
      margin-bottom: 5px;

      .location-name {
        font: normal normal 600 14px/24px Open Sans;
        letter-spacing: 0px;
        color: #222222;
      }

      .message {
        font: normal normal 600 12px/22px Montserrat;
        letter-spacing: 0px;
        color: #0f3447 !important;

        .unsubscribed {
          background: #ffe3e3 0% 0% no-repeat padding-box;
          border: 1px solid #ffbdbd;
          border-radius: 5px;
          padding: 3px 5px;
          display: flex;
        }

        .fallOutside {
          background: #fdfae9 0% 0% no-repeat padding-box;
          border: 1px solid #fef4be;
          border-radius: 5px;
          padding: 3px 5px;
          display: flex;
        }
      }
    }
  }
}

.creative-channels {
  height: 100%;
  max-height: 450px;

  .card {
    box-shadow: 0px 12px 40px #00000017;
    border: 1px solid #c1c1c1;
    border-radius: 20px;
  }

  .creative-carousel {
    min-height: 400px;
    overflow-x: hidden;
    padding: 20px 45px;

    .slick-slide {
      img {
        max-height: 320px;
        margin: auto;
        border: 1.5px solid rgba($text-color-main, 0.7);
        border-radius: 8px;
        box-shadow: 0px 10px 10px #00000017;
      }
    }

    .slick-arrow {
      background-color: rgba($primary-color, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $border-radius;

      &::after {
        display: none;
      }

      &::before {
        display: none;
      }
    }

    .slick-prev,
    .slick-next {
      width: 2rem;
      height: 2rem;
    }

    .slick-prev {
      left: -35px;
    }

    .slick-next {
      right: -35px;
    }
  }

  .creative-channel {
    text-align: center;
    padding: 5px 10px;

    .channel-name {
      font: normal normal bold 12px/17px Open Sans;
      letter-spacing: 0px;
      color: #0f3447;
      text-transform: uppercase;
    }

    .creative-name {
      font: normal normal bold 11px/15px Open Sans;
      letter-spacing: 0px;
      color: #219084;
      margin-top: 5px;
    }
  }
}

/*********** Creative channel modal ************/
.creative-channel-modal {
  #creative-channels-modal-title {
    .location-name {
      font: normal normal bold 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #2b9084;
      text-align: center;
      padding: 0 13px;
    }

    .creative-name {
      font: normal normal 600 14px/18px Montserrat;
      letter-spacing: 0px;
      color: #0f3447;
      text-align: center;
    }
  }

  .creative-channels {
    max-height: 450px;
    height: 100%;
    max-height: 100%;
    min-width: 280px;

    .creative-channel {
      padding: 2px 5px;
      text-align: center;
      padding: 40px;
    }

    .creative-carousel {
      padding: 5px 0;

      .slick-slide {
        img {
          max-height: 400px;
        }
      }

      .slick-prev {
        left: 5px;
        z-index: 99;
      }

      .slick-next {
        right: 5px;
        z-index: 99;
      }
    }
  }
}

/************ React Slick Carousel *********/
.finite-center {
  transform: translate3d(0, 0, 0) !important;
}

/*************Rp Confirm ***************/
.redux-toastr {
  .rrt-confirm {
    border-radius: 20px;
    overflow: initial;

    .rrt-message {
      border-radius: 20px;
    }

    .rrt-buttons-holder {
      .rrt-ok-btn {
        border-radius: 0 0 0 20px;
        background: #219084 0% 0% no-repeat padding-box;
        color: #fff;
        font-weight: $fontWeight-Semi-Bold;

        &:hover,
        &:active {
          background: rgba(33, 144, 132, 0.8) 0% 0% no-repeat padding-box !important;
          color: #fff;
          // background-color: #60bb71;
          // color: white;
        }
      }

      .rrt-cancel-btn {
        border-radius: 0 0 20px 0;
        background: #e0e4e8 0% 0% no-repeat padding-box;
        font-weight: $fontWeight-Semi-Bold;

        &:hover {
          background-color: #db6a64;
          color: white;
        }
      }
    }
  }
}

.rp-confirm {
  .img-box {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-top: -60px;
    border: 5px solid rgba(50, 58, 68, 0.8);
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .heading {
    font: normal normal bold 22px/25px Montserrat;
    letter-spacing: 0px;
    color: #fa5b5b;
    margin: 1rem auto;
  }

  p {
    font: normal normal 600 12px/20px "Open Sans";
    letter-spacing: 0px;
    color: #0f3447;
  }
}

/*** react-checkbox-tree ***/

.react-checkbox-tree {
  max-width: 100%;
  overflow-x: auto;
  padding: 5px 5px 2rem;

  & > ol {
    & > li {
      //font: Semibold 16px/22px Open Sans;
      @include font(13px, #626266, $fontWeight-Semi-Bold, 22px);

      ol {
        li {
          // font: Regular 16px/22px Open Sans;
          @include font(13px, #7c7c7c, null, 22px);
        }
      }
    }
  }

  .rct-node {
    margin-bottom: 0.5rem;
  }

  .rct-collapse {
    width: 34px;
  }

  label {
    display: flex;
    align-items: center;

    &:hover {
      background: transparent !important;
    }

    &:focus {
      background: transparent !important;
    }
  }

  .rct-node-clickable {
    &:hover {
      background: transparent !important;
    }

    &:focus {
      background: transparent !important;
    }
  }
}

.react-checkbox-tree:not(.rct-native-display) input[type="text"] {
  display: block !important;
  margin-left: 1px;
}

.rct-node-leaf {
  label {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
}

.rct-title {
  // max-width: 550px;
  // min-width: 360px;
  // display: flex;
  // align-items: center;
  width: 100%;

  .label {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .brand-contribution {
    // width: 300px;
    margin-left: 1px;
  }
}

.page-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 79px;
  left: 0;
  right: 0;

  .page-title {
    background: #113f5a 0% 0% no-repeat padding-box;
    color: #fff;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    border-radius: 0px 2px 20px 20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-radius: 0px 0px 200px 200px;
  }
}

/*** DashBoard **/

.dashboard {
  &.admin {
    .total-figures {
      .previous-month {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: #42a32e;
        }
      }
    }

    .widget-data-filter {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 100px;
      padding: 0.2rem;
    }

    .custom-date-range-picker {
      .react-daterange-picker__wrapper {
        border: 1px solid #ddd;
        border-radius: 100px;
        padding: 0.1rem 0.5rem;
        font-weight: 500;

        .MuiSvgIcon-fontSizeSmall {
          font-size: 1.2rem;
        }

        .react-daterange-picker__button {
          padding: initial;
        }
      }

      .react-daterange-picker__inputGroup__input {
        color: #3c8f83;
        color: #000;
      }
    }
  }

  &.participant-dashboard {
    .section-title {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      color: #00000b;
    }

    .title {
      font-size: 0.6rem;
      letter-spacing: 0px;
      color: #102d39;
    }

    .title-val {
      font-weight: $fontWeight-Semi-Bold;
      font-size: 0.7rem;
      letter-spacing: 0px;
      color: #1b262c;

      &.main {
        color: #2b9084 !important;
      }
    }

    .campaign-name {
      font-size: 0.8rem;
      font-weight: 600;
    }

    .card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border: 1px solid #e3e6f0;
      border-radius: $border-radius;

      .card-footer {
        padding-top: 0;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0;
        border-radius: $border-radius;
      }
    }

    .heading-card {
      background: #f1f1f1;
      box-shadow: none;

      .title {
        font-weight: $fontWeight-Semi-Bold;
        font-size: 0.7rem;
      }
    }

    .yet-to-join-campaigns {
      .brand-match {
        // border-color: lighten($text-color-main, 40%);
      }

      .brand-match-tag {
        position: absolute;
        top: 0;
        font-size: 10px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        text-align: center;

        .tag {
          background-color: $text-color-main;
          color: #fff;
          padding: 0.1rem 1rem;
          border-radius: 0 0 5px 5px;
        }
      }

      .learn-more-button {
        display: flex;
        align-items: center;
        font-size: 10px;
        height: 32px;
        position: relative;
        background: #fff9f2 0% 0% no-repeat padding-box;
        border: 1px solid #ffe6b8;
        border-radius: 5px;
        font-weight: $fontWeight-Semi-Bold;
        letter-spacing: 0px;
        color: #fea401;

        .right-arrow {
          height: 100%;
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20%;
          background: #fda70b 0% 0% no-repeat padding-box;
          color: #fff;
          border-radius: 0px 5px 5px 0px;
        }
      }

      .grid-view {
        .learn-more-button {
          font-size: 11px;

          span {
            margin-left: 3px;
          }
        }
      }
    }

    .joined-campaigns {
      .title-val {
        font-weight: normal;
      }
    }
  }
}

/** Table search **/
.table-search {
  .input-group {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bfc2c2;
    border-radius: $border-radius;

    .input-group-text {
      border: 0;
    }
  }

  .table-search-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: $border-radius;
    border: 0;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &::placeholder {
      // font: Regular 16px/19px Montserrat;
      @include font(13px, #666666);
    }
  }

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}

/** rp-number-figure **/
.rp-number-figure {
  .previous-month {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 11px;
    }
  }

  .title {
    min-height: 38px;
    font-size: 1rem !important;
  }

  .widget-title {
    color: #516068;
  }

  .widget-state-value {
    color: #002938;
    font-weight: $fontWeight-Semi-Bold;
  }
}

/** RP Custom DropDown **/
.rp-custom-dropdown {
  label {
    // font: Semibold 18px/30px Open Sans;
    @include font(15px, #6e6e6e, $fontWeight-Semi-Bold);

    &:focus {
      color: #6e6e6e !important;
    }
  }

  .MuiInputBase-root {
    @include font(15px, #83898e, $fontWeight-Semi-Bold);
    line-height: 1.1875em;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #91919127;
    border: 1px solid #eaeaea;
    border-radius: 6px;

    &:hover {
      border: 1px solid #eaeaea;
    }

    &:focus {
      outline: 0;
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 3px) scale(0.75);
  }
}

/** Charts **/
.charts {
  .heading {
    height: 35px;
    border-bottom: 1px dashed #ccc;
  }
}

.chart-container {
  max-width: 90%;
  max-height: 90%;
  min-height: 200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** doughnut **/
.doughnut-chart {
  .label-box {
    display: flex;
    align-items: center;

    .color {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }

  .data {
    max-height: 90%;
    height: 85%;
    display: flex;
    flex-direction: column;

    //justify-content: space-around;
    // justify-content: center;
    & > div {
      margin-bottom: 1rem;
    }
  }
}

/** Progress chart**/
.progress-chart {
  .heading {
    border-bottom: 1px dashed #ccc;
    height: 35px;
  }

  .bar-container {
    margin: 1rem auto;
  }

  .bar {
    height: 10px;
    border-radius: 0;

    .progress-bar {
      background-color: #09aba7;
    }
  }
}

.chart.table-cart,
.chart.progress-chart {
  margin-bottom: auto;
}

.chart.table-cart {
  margin-top: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;

  .table-responsive {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dbebe9;
  }

  thead {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border-radius: 5px;

    th {
      color: $text-color;

      font-weight: $fontWeight-Bold span {
        font-size: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 11px;
      }

      &:nth-child(2n) {
        background: #fcfcfc 0% 0% no-repeat padding-box;
      }

      &:hover {
        background: rgba(4, 169, 153, 0.1);
      }
    }
  }

  tr {
    td:first-child,
    th:first-child {
      padding-left: 1rem;
    }

    td:last-child,
    th:last-child {
      padding-right: 0.5rem;
    }
  }
}

/***Redux Toastr***/
.redux-toastr {
  .toastr {
    .rrt-left-container {
      width: 50px;

      .rrt-holder {
        width: 50px;
        height: 70px;
      }
    }

    .rrt-middle-container {
      width: 74%;
      margin-left: 55px;

      & > div {
        line-height: 1.1;
      }
    }
  }
}

/**Help Text Material UI***/
.MuiFormHelperText-contained {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

/**Modal Bootstrap***/
.modal-header {
  align-items: center;
}

/** Select Brand **/
.select-brand {
  position: relative;

  .brands {
    overflow-y: auto;
  }

  .brand {
    height: 100%;
    width: 100%;
    padding: 1rem;
    margin: auto;
    transition: all 0.1s ease-in-out;
    background: #f2f3f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    text-align: center;
    font: normal normal 600 14px/22px Open Sans;
    letter-spacing: 0px;
    color: #0f3447;

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      background: #fff;
      cursor: pointer;
    }
  }

  p {
    font: normal normal normal 14px/22px Open Sans;
    letter-spacing: 0px;
    color: #67676a;
  }
}

/*************** Link account *************/
.link-account {
  .heading {
    font: normal normal bold 14px/19px Montserrat;
    letter-spacing: 0px;
    color: #222222;
    padding: 1rem;
    text-align: center;
  }
}

/**************404*************/
.not-found-404 {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    padding: 1rem;
    background-color: $text-color-main;
  }
}

/** Box container**/
.box-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #91919127;
  border: 1px solid #eaeaea;
  border-radius: 6px;
}

/****** NO UI Slider **/
.noUi-connect {
  &:first-child {
    background: #e99894 !important;
  }

  &:nth-child(2) {
    background: #29a8a5 !important;
  }
}

.noUi-handle-lower {
  .noUi-touch-area {
    background: #e99894 !important;
  }

  .noUi-tooltip {
    color: #103044 !important;
  }
}

.noUi-handle {
  border: 0 !important;
  border-radius: 100% !important;
}

.noUi-handle-upper {
  .noUi-touch-area {
    background: #29a8a5 !important;
  }

  .noUi-tooltip {
    color: #29a8a5 !important;
  }
}

.noUi-tooltip {
  border: 0 !important;
}

.noUi-handle-lower .noUi-tooltip {
  top: 30px !important;
}

/*************react-daterange-picker ***********/
.react-daterange-picker {
  .react-calendar__tile--active {
    background: #45988f;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #45988f;
  }

  // .react-calendar__tile--now,
  // .react-calendar__tile--now:enabled:hover,
  // .react-calendar__tile--now:enabled:focus {
  //   border: 1px solid rgba(69, 152, 143, 0.5);
  // }
}

/*************Brand Dashboard******************/
.date-range-picker {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

/********************* Widget Template *************/
.widget-template {
  .card {
    min-width: 150px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d6eaf7;
    border-radius: 15px;
  }

  .section {
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 40px #00000017;

    .section-header {
      border-radius: 10px 10px 0 0;
      padding: 1.25rem;
    }

    .section-title {
      position: relative;
      font-size: 20px;
      color: #83898e;
      font-weight: $fontWeight-Semi-Bold;
      line-height: initial;
      letter-spacing: 0;
      margin-bottom: 0;
    }
  }

  .widget {
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 20px #5160681a;
    border: 1px solid #e3e6f0;

    &:hover {
      box-shadow: 2px 13px 50px #5160681a;
    }

    .card-header {
      position: relative;
      background-color: #fff;
      border: 0;
      border-radius: 10px 10px 0 0;
      padding: 1.25rem;
      padding-bottom: 0;
    }

    .widget-title {
      position: relative;
      background-color: #fff;
      border: 0;
      font-weight: $fontWeight-Semi-Bold;
      letter-spacing: 0px;
      color: #000000;
      font-size: 15px;
      padding-left: 10px;
      border-left: 4px solid $text-color-main;
    }

    &.widget_type_stats {
      border-bottom: 5px solid $primary-color;

      .card-body {
        padding: 0.5rem;
      }

      .widget-title {
        color: #516068;
        font-size: 12px;
        // background: #eaf7ff 0% 0% no-repeat padding-box;
        border-radius: 8px;
        padding: 1rem 0.7rem;
        border: 0;
      }

      .widget-state-value {
        font-weight: $fontWeight-Semi-Bold;
        letter-spacing: 0px;
        color: #143c4b;
        padding: 0.7rem;
      }
    }

    &.widget_type_chart_graph {
      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow-y: auto;
        // padding-top: 0;
      }
    }

    &.widget_type_table {
      .card-body {
        padding-top: 5px;
      }
    }
  }
}

.test {
  max-width: 100%;
  overflow: hidden;

  // position: relative;
  // padding-bottom: 56.25%;
  .sheet-view + div {
    position: none !important;
    width: 100% !important;
  }
}

.is_embed {
  //iframe {
  // width: 570px;
  // height: 380px;
  // border: 0;
  // transform: scale(0.92);
  // margin-left: -45px;

  // width: 585px;
  // height: 380px;

  // border: 0;
  // min-width: 100%;
  // max-width: 100%;
  // min-height: 350px;
  // height: auto;
  // border-radius: 10px;
  //}

  &.card-body {
    padding-inline: 0.25rem;
  }

  .iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    // padding-top: 34.25%;
    /* Ratio for (9 / 16) aspect ratio */
    min-height: 100px;
    // min-height: 350px;
    height: auto;
  }

  .iframe-container iframe {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    min-height: 100%;
    border: 0;
    border-radius: 10px;
  }

  // @media screen and (max-width:767px) {
  //   .iframe-container {
  //     min-height: 200px;
  //   }
  // }
}

/**************** Report ******************/
.reportPdfContainer {
  // margin-left: 0 !important;
  // margin-right: 0 !important;
  // padding-right: 0 !important;
  // padding-left: 0 !important;
  // max-width: 100% !important;
  // &.container.section-header {
  //   padding-left: 15px !important;
  //   padding-right: 15px !important;
  // }

  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  max-width: 1300px !important;

  &.container.section-header {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.report {
  &.campaign-report {
    .campaign-report-header {
      .card {
        border-radius: 10px;
        border: 0;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 2px 13px 50px #5160681a;
      }

      .card-header {
        background-color: $text-color-main !important;
        color: #fff;
        border-radius: 10px 10px 0 0;
      }
    }

    .campaign-detail {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border-radius: 10px;
      border: 0;
      font-size: 0.7rem;

      .title {
        color: #516068;
      }

      .value {
        font-size: 0.8rem;
        color: #1b262c;
        font-weight: $fontWeight-Bold;

        &.main {
          color: $text-color-main;
        }
      }
    }
  }
}

/********** Location Report *******/
.location-report-container {
  .location-detail {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 40px #00000017;
    font-size: 0.7rem;
    border-radius: 10px;

    .card {
      border-radius: 10px;
      border: 0;
    }

    .title {
      color: #516068;
    }

    .value {
      color: #1b262c;
      font-weight: $fontWeight-Semi-Bold;
      font-size: 0.8rem;
    }
  }
}

/******************* Date Picker ****************/
.MuiFormControl-root.MuiTextField-root.border-0 {
  .Mui-disabled {
    background-color: #eff2ff;
    padding-left: 0;
  }

  fieldset {
    border: 0;
  }
}

/******************************** SELF SUBSCRIBE *******************************/
.self-subscription-page {
  .top-section {
    // background-color: #0e3044;
    background: linear-gradient(180deg, #0e3044 70%, #ffffff 30%);
    padding: 2rem 1rem;

    .main-heading {
      text-align: center;
      color: #fff;
      opacity: 0.8;
      font-weight: 400;
    }

    .secondary-heading {
      text-align: center;
      font-weight: 600;
      color: #fff;
      opacity: 0.8;
    }

    .plans {
      margin-top: 3rem;

      .subscription-plan {
        text-align: center;
        height: 100%;

        &:hover {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        }

        .title-box {
          min-height: 40px;
        }

        .title {
          font-size: 14px;
          font-weight: $fontWeight-Semi-Bold;
        }

        .discount-text {
          font-size: 12px;
          font-weight: $fontWeight-Semi-Bold;
        }

        .subscription-amount {
          color: $text-color-main;
          font-size: 1.2rem;
          margin-bottom: 0;

          span {
            font-size: 2.5rem;
            font-weight: $fontWeight-Semi-Bold;
          }
        }

        .amount-description {
          color: $text-color-main;
        }

        .package-description {
          // font-size: 0.8rem;
          // font-weight: 600;
          // margin: 1rem auto;
          margin-top: 1rem;

          p {
            line-height: 1.8;
            font-size: 0.8rem;
            margin-bottom: auto;
            font-weight: $fontWeight-Semi-Bold;
          }
        }

        .subscription-form {
          text-align: left;

          label {
            color: $text-color-main;
            font-size: 14px;
            font-weight: 600;
          }

          input {
            border: 1px solid $text-color-main;
          }

          .error {
            margin: 0.5rem auto;
            color: red;
          }
        }
      }
    }
  }

  .bottom-section {
    padding-bottom: 3rem;

    .main-heading {
      color: $text-color-violet;
      text-align: center;
      font-weight: 600;
    }

    .descriptive-text {
      font-weight: 600;
      text-align: center;
    }

    .what-you-get-by-subscribe {
      .benefit {
        height: 100%;

        &:hover {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        }

        .content {
          color: $text-color-violet;
          text-align: center;
          font-weight: 400;
        }

        img {
          width: 50px;
          max-height: 50px;
          margin: 2rem auto;
        }
      }
    }

    .contact-us {
      .contact-email {
        font-weight: 600;
        font-size: 1.25rem;

        a {
          color: $text-color-violet;
        }
      }
    }

    .current-campaigns-available {
      margin: 4rem auto;

      .card {
        height: 100%;

        &:hover {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        }

        .row {
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            max-height: 200px;
            object-fit: cover;
          }
        }
      }

      .campaign-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      .campaign-name {
        color: $text-color-main;
        font-size: 1.3rem;
        font-weight: 400;
      }

      .d-heading {
        color: $text-color-violet;
        font-size: 1rem;
      }

      .dates {
        color: $text-color-violet;
        font-size: 0.8rem;
      }
    }
  }
}

/**************** Footer ***************************/
footer {
  background: #0f3447 0% 0% no-repeat padding-box;
  padding: 1rem;
  font-size: 10px;
  font-weight: $fontWeight-Regular;
  letter-spacing: 0px;
  color: #ffffff;
}

/*************React Player **********/
.player-wrapper {
  position: relative;
  padding-top: 29.25%;
  padding-bottom: 29.25%;
  /* Player ratio: 100 / (1280 / 720) */
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  iframe {
    border-radius: $border-radius;
  }
}

.react-player__preview {
  background-repeat: no-repeat;
  // background-size: contain !important;
  height: 110% !important;
  border-radius: $border-radius;
}

/*****************Sign up*************************/
.sign-up {
  .heading {
    color: $text-color-main;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .subheading {
    color: $text-color-main;
  }

  .email-with-link-send-message {
    text-align: center;
  }
}

/*********** Validate email signup link ***************/
.validate-email-signup-link {
  .heading {
    color: $text-color-main;
    font-weight: $fontWeight-Semi-Bold;
    margin-bottom: 1.5rem;
  }

  .subheading {
    color: $text-color-main;
  }
}

/********** Create Password **************/
.create-password {
  background: linear-gradient(180deg, #e6eef2 350px, #f7f7f7 0);
  min-height: 97vh;

  .greeting {
    font-size: 14px;
  }

  .user-name {
    font-weight: 600;
    text-transform: capitalize;
  }

  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #283f6724;
    border-radius: 20px;
    border: 0;
  }

  .create-password-form {
    .heading {
      font-weight: $fontWeight-Semi-Bold;
      font-size: 28px;
      margin-bottom: 1rem;
    }
  }
}

/**************** React Table *************/
.rp-react-table {
  // margin-left: -1.25rem;
  // margin-right: -1.25rem;

  .table-responsive {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dbebe9;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      &::-webkit-scrollbar {
        height: 7px;
      }
    }

    // &::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // }

    &::-webkit-scrollbar-thumb {
      background-color: $text-color-main;
    }
  }

  table {
    box-shadow: none !important;

    thead {
      background: #f1f1f1 0% 0% no-repeat padding-box;
      border-radius: 5px;

      tr {
        border-radius: 5px;
        border: 0;

        // padding: 10px 0;
        th {
          font-size: 12px;
          line-height: 1.5;
          color: $text-color;
          font-weight: $fontWeight-Bold;
          color: #102d39;
          position: relative;
          padding-top: 1rem;
          padding-bottom: 1rem;
          border-right: 1px solid #e1e1e1;

          &:last-child {
            border-right: 0;
          }

          .sorting-arrow {
            position: absolute;
            left: calc(100% - 15px);
            top: 0;
            height: 100%;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // svg {
            //   font-size: 0.9rem;
            //   background: #04a99933;
            // }
          }
        }
      }
    }

    tbody {
      tr {
        background: #ffffff 0% 0% no-repeat padding-box;

        &:nth-child(2n) {
          background: #fcfcfc 0% 0% no-repeat padding-box;
        }

        &:hover {
          background: rgba(4, 169, 153, 0.1);
        }

        td {
          font-size: 12px;

          & > span {
            color: #1b262c;
          }
        }
      }
    }
  }
}

/******************************user-locations-subscriptions ****************/
.user-locations-subscriptions {
  .subscription-overview {
    .stats {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 2px 13px 50px #5160681a;
      border: 1px solid #e3e6f0;
      border-radius: 10px;
      border-bottom: 5px solid $primary-color;

      .widget-title {
        padding: 1rem 0rem;
        color: #516068;
        font-size: 0.8rem;
      }
    }
  }

  .user-subscriptions {
    .table-btn-link {
      padding: 5px 11px !important;
      font-size: 11px;
      background-color: white !important;
      text-decoration: underline;
      color: #3a9dff;
      font-weight: $fontWeight-Bold;
    }

    .rp-react-table {
      margin: auto;
    }

    td {
      font-size: 0.8rem;
    }
  }
}

/******************************* MS Activation ***********************/

@keyframes dropDownOpen {
  0% {
    transform: scaleY(0);
  }

  50% {
    transform: scaleY(0.8);
  }

  100% {
    transform: scaleY(1);
  }
}

.ms-activation-brief {
  padding: 1rem;

  .main-heading {
    font-size: 32px;
    font-weight: $fontWeight-Bold;
    color: #0f3447;
  }

  .description {
    color: #67676a;
  }

  .section-heading {
    font-size: 25px;
    font-weight: 600;
    color: #0f3447;
  }

  label {
    font-weight: 700;
    font-size: 14px;
    color: #0f3447;
  }

  .ms-activation-brief-form {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #d1d5df33;
    border-radius: 10px;
    border: 0;
    position: relative;

    .other_area_of_focus {
      height: 60px;
    }

    .other_area_of_focus_form_group {
      pointer-events: none;

      .MuiCheckbox-root,
      .MuiInputBase-root {
        pointer-events: all;
      }
    }

    .email-list-box {
      padding: 1rem;
      border-radius: 4px;
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.23);
      min-height: 40px;
    }

    .state-list-box {
      padding: 7px 10px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      position: relative;
      height: 40px;
      display: flex;
      padding-right: 30px;

      &:hover {
        border: 1px solid $primary-color;
      }

      .rp-custom-drop-down {
        display: none;
        position: absolute;
        top: 103%;
        right: 0;
        width: 100.1%;
        height: 300px;
        overflow-y: scroll;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 15px #00000012;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        padding: 0px 10px;
        z-index: 1;
        border-radius: 5px;
        transition: display 0.5s;
        font-size: 12px;
        box-shadow:
          rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
          rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

        &.open {
          display: block;
          animation: dropDownOpen 200ms ease-in-out forwards;
          transform-origin: top center;
        }

        .MuiFormControlLabel-root {
          margin-bottom: 0;

          .MuiFormControlLabel-label {
            font-size: 14px;
            color: #0f3447;
          }
        }
      }

      .data-box {
        max-height: 100%;
        height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .expand_icon {
        position: absolute;
        right: 2px;
        z-index: 1;
      }
    }
  }
}

img.ms-help-img {
  width: 75px;
  position: fixed;
  right: 5%;
  bottom: 50px;
  cursor: pointer;
  z-index: 9;
}

.help-message {
  p,
  a {
    font-size: 12px;
  }

  .header {
    background-color: #b0c4de54;

    .heading {
      font-weight: 700;
    }

    .close {
      position: absolute;
      right: 7px;
      top: 7px;
      cursor: pointer;
    }
  }

  .email {
    font-weight: 700;
  }
}

.ms-package-card {
  position: relative;
  background: #fbfffc 0% 0% no-repeat padding-box;
  border: 2px solid #229084;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  height: 100%;

  .recommended {
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;

    span {
      background: #229084 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px 4px 0 0;
      padding: 0.2rem 1rem;
      color: #fff;
      font-weight: $fontWeight-Semi-Bold;
    }
  }

  .package-tier {
    color: #229084;
    font-size: 18px;
  }

  .package-name {
    color: #229084;
    font-size: 18px;
    font-weight: $fontWeight-Bold;
  }

  .price {
    color: #0f3447;
    font-size: 40px;
    display: flex;
    align-items: center;

    .price-meta {
      color: #7a797e;
      font-size: 14px;
      margin-left: 1rem;
    }
  }

  .description {
    color: #0f3447;
    font-size: 12px !important;
  }

  .price-small {
    color: #0f3447;
    font-size: 20px;
    font-weight: 600;
  }

  .media-budget {
    color: #0f3447;
    font-weight: 600;

    .media-budget-meta {
      font-size: 12px;
    }

    .media-budget-range {
      font-size: 20px;
    }
  }

  .perks {
    ul {
      padding-left: 0.7rem;

      li {
        font-size: 10px;
        color: #0f3447;

        &::marker {
          content: "✓";
          font-size: 14px;
          margin-right: 10px;
          color: #2b9084;
        }
      }
    }
  }
}

/******************************* MS Activation ***********************/

@keyframes dropDownOpen {
  0% {
    transform: scaleY(0);
  }

  50% {
    transform: scaleY(0.8);
  }

  100% {
    transform: scaleY(1);
  }
}

.ms-activation-brief {
  padding: 1rem;

  .main-heading {
    font-size: 32px;
    font-weight: $fontWeight-Bold;
    color: #0f3447;
  }

  .description {
    color: #67676a;
  }

  .section-heading {
    font-size: 25px;
    font-weight: 600;
    color: #0f3447;
  }

  label {
    font-weight: 700;
    font-size: 14px;
    color: #0f3447;
  }

  .ms-activation-brief-form {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #d1d5df33;
    border-radius: 10px;
    border: 0;
    position: relative;

    .other_area_of_focus {
      height: 60px;
    }

    .other_area_of_focus_form_group {
      pointer-events: none;

      .MuiCheckbox-root,
      .MuiInputBase-root {
        pointer-events: all;
      }
    }

    .email-list-box {
      padding: 1rem;
      border-radius: 4px;
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.23);
      min-height: 40px;
    }

    .state-list-box {
      padding: 7px 10px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      position: relative;
      height: 40px;
      display: flex;
      padding-right: 30px;

      &:hover {
        border: 1px solid $primary-color;
      }

      .rp-custom-drop-down {
        display: none;
        position: absolute;
        top: 103%;
        right: 0;
        width: 100.1%;
        height: 300px;
        overflow-y: scroll;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 15px #00000012;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        padding: 0px 10px;
        z-index: 1;
        border-radius: 5px;
        transition: display 0.5s;
        font-size: 12px;
        box-shadow:
          rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
          rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

        &.open {
          display: block;
          animation: dropDownOpen 200ms ease-in-out forwards;
          transform-origin: top center;
        }

        .MuiFormControlLabel-root {
          margin-bottom: 0;

          .MuiFormControlLabel-label {
            font-size: 14px;
            color: #0f3447;
          }
        }
      }

      .data-box {
        max-height: 100%;
        height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .expand_icon {
        position: absolute;
        right: 2px;
        z-index: 1;
      }
    }
  }

  .packages-detail {
  }
}

img.ms-help-img {
  width: 75px;
  position: fixed;
  right: 5%;
  bottom: 50px;
  cursor: pointer;
  z-index: 9;
}

.help-message {
  p,
  a {
    font-size: 12px;
  }

  .header {
    background-color: #b0c4de54;

    .heading {
      font-weight: 700;
    }

    .close {
      position: absolute;
      right: 7px;
      top: 7px;
      cursor: pointer;
    }
  }

  .email {
    font-weight: 700;
  }
}

.ms-package-card {
  position: relative;
  background: #fbfffc 0% 0% no-repeat padding-box;
  border: 2px solid #229084;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  height: 100%;

  .recommended {
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;

    span {
      background: #229084 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px 4px 0 0;
      padding: 0.2rem 1rem;
      color: #fff;
      font-weight: $fontWeight-Semi-Bold;
    }
  }

  .package-tier {
    color: #229084;
    font-size: 18px;
  }

  .package-name {
    color: #229084;
    font-size: 18px;
    font-weight: $fontWeight-Bold;
  }

  .price {
    color: #0f3447;
    font-size: 40px;
    display: flex;
    align-items: center;

    .price-meta {
      color: #7a797e;
      font-size: 14px;
      margin-left: 1rem;
    }
  }

  .description {
    color: #0f3447;
    font-size: 12px !important;
  }

  .price-small {
    color: #0f3447;
    font-size: 20px;
    font-weight: $fontWeight-Semi-Bold;
  }

  .media-budget {
    color: #0f3447;
    font-weight: $fontWeight-Semi-Bold;

    .media-budget-meta {
      font-size: 12px;
    }

    .media-budget-range {
      font-size: 20px;
    }
  }

  .perks {
    ul {
      padding-left: 0.7rem;

      li {
        font-size: 10px;
        color: #0f3447;

        &::marker {
          content: "✓";
          font-size: 14px;
          margin-right: 10px;
          color: #2b9084;
        }
      }
    }
  }
}

/****************************** Manage Active Subscription ****************/
.manage-active-subscription {
  min-height: 100vh;

  main {
    .MuiStepper-root {
      background-color: #f7f7f7;
    }
  }

  .main-heading {
    letter-spacing: 0px;
    margin-bottom: 2rem;
    color: $secondary-color;
  }

  .sub-heading {
    font-size: small;
  }

  .description-text {
    font-weight: $fontWeight-Bold;
    font-size: small;
  }

  .subscribed-locations {
    .locations {
      .location {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 40px #00000016;
        border-radius: $border-radius;
        margin-bottom: 1rem;

        .location-name {
          font-weight: 600;
        }

        .subscription-date {
          font-size: 0.8rem;

          span {
            font-size: 0.8rem;
            font-weight: 600;
          }
        }

        .seperator {
          color: lightgray !important;
        }

        .package-selection-btn {
          padding: 5px 22px;
          font-size: 11px;
        }

        .opt-out-btn {
          padding: 5px 4px !important;
          font-size: 11px;
          background-color: white !important;
          text-decoration: underline;
          color: #3a9dff;
        }

        .current-sub-date {
          font-weight: 600;
        }

        .package-name {
          font-weight: 600;
          color: $primary-color;
        }
      }

      .location[disabled] {
        background-color: #ebebeb;
        color: #003044;
        margin-bottom: 5px;
        border-radius: 5px;

        .subscription-disabled-dates {
          font-weight: 700;
          color: #003044;
        }

        .package-detail {
          color: #999999;
          font-weight: 700;
        }
      }
    }

    .package-selection-summary {
      position: sticky;
      top: 100px;
      box-shadow: 0px 8px 40px #00000016;
      border: 0;

      .heading {
        font: normal normal 600 19px/25px Montserrat;
        letter-spacing: 0px;
        color: #0f3447;
      }

      .selections {
        padding-right: 4px;
        max-height: 250px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: $text-color-main;
        }
      }

      .location-name {
        letter-spacing: 0px;
        font-weight: 700;
      }

      .package-info {
        letter-spacing: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .total {
        font: normal normal 600 15px/25px Montserrat;
        letter-spacing: 0px;
        color: #0f3447;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
      }

      .note {
        font: normal normal 600 14px/22px Open Sans;
        letter-spacing: 0px;
        color: #0f3447;
      }
    }
  }
}

.change-package-dialog {
  z-index: 99999 !important;
}

.manage-active-subscription-package-details {
  .main-heading {
    letter-spacing: 0px;
    margin-bottom: 2rem;
    color: $secondary-color;
  }

  .sub-heading {
    font-size: 0.8rem;
  }

  .description-text {
    font-weight: $fontWeight-Bold;
    font-size: small;
  }

  .package-selection-btn {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: $primary-contrast-color;
    padding: 3px 30px;
    box-shadow: none;
  }

  .package-selection-btn:hover {
    box-shadow: none;
    background-color: $primary-contrast-color;
  }

  .package-selection-btn:active {
    border: 1px solid $primary-color;
    color: $primary-contrast-color;
    background-color: $primary-color;
  }

  .renew-package-card {
    cursor: pointer;

    .package-card {
      position: relative;
      text-align: left;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 8px 40px #00000016;
      border-radius: 10px;
      border: 0;
      transition: all 0.2s ease-in-out;

      &.recommended_package {
        border: 1px solid $primary-color;
      }

      .disabled_recommended_package {
        border: 1px solid red !important;
      }

      .recommended {
        letter-spacing: 0px;
        padding: 0.5rem 0.5rem;
        background: #04a99933;
        color: $primary-color;
        border-radius: 5px;
        text-align: center;
        width: auto;
        font-size: 0.7rem;
        font-weight: 600;
      }

      .name {
        letter-spacing: 0px;
        color: $primary-color;
        font-weight: $fontWeight-Semi-Bold;
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #0f3447;
        margin-bottom: 0;
      }

      .val {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #0f3447;
      }

      .total {
        letter-spacing: 0px;
        vertical-align: middle;
      }

      .total-info {
        color: #c1c1c1;
        letter-spacing: 0px;
        font-weight: 100;
        font-size: 1rem;
      }

      .description {
        letter-spacing: 0px;
        color: #0f3447;
        font-size: 0.8rem;

        ul {
          padding-left: 18px;
          list-style: none;

          li {
            position: relative;
            margin-bottom: 10px;

            &::before {
              content: "";
              position: absolute;
              border-color: $text-color-main;
              border-style: solid;
              border-width: 0 0.2em 0.2em 0;
              height: 1em;
              top: 1.3em;
              left: -1.2em;
              margin-top: -1em;
              transform: rotate(45deg);
              width: 0.5em;
            }
          }
        }
      }
    }

    &.selected {
      .package-card {
        background: $primary-color;
        color: $primary-contrast-color;

        .name,
        .total,
        .total-info,
        .description {
          color: $primary-contrast-color;
        }

        .description ul li::before {
          border-color: $primary-contrast-color !important;
        }

        .recommended {
          color: $primary-contrast-color !important;
          background: #36baad;
        }
      }
    }
  }
}

/**  ###################################### Responsive ##################### **/

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  // .user-profile {
  //   min-width: 125px;
  // }

  /** Sub Header Responsive **/
  .sub-header {
    .user-details {
      .welcome {
        font-size: 8px;
      }

      .name {
        font-size: 13px;
      }
    }

    .date-time {
      min-width: 125px;
      max-width: 125px;

      .time {
        font-size: 19px;
      }

      .date {
        font-size: 7px !important;
      }
    }
  }

  .thank-you-page {
    height: 100%;
  }

  .profile {
    .id-box {
      .id {
        font-size: 12px;
      }
    }
  }

  .page-title-box {
    .page-title {
      font-size: 12px;
    }
  }

  .auth-left-view {
    .logo-image-box {
      min-height: 60px;
    }

    .logo-image-bg {
      left: -7px;
      width: 75px !important;
      height: 75px;
    }

    .logo-image {
      width: 50px;
      bottom: -19px;
    }

    .heading {
      font-size: 20px !important;
      line-height: normal;
    }

    p {
      font-size: 13px;
      line-height: normal;
      margin-bottom: 2px;
    }
  }

  .login-form {
    .heading {
      font-size: 25px;
    }

    .subheading {
      font-size: 16px;
    }
  }

  .join-campaign-page {
    .join-campaign-form {
      .heading {
        font-size: 16px;
      }

      .locations {
        .location-header {
          .location-header-content {
            .location-name {
              font-size: 16px;
            }

            .location-meta {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

/* Up to SM */
@media (max-width: 767.98px) {
  .auth-left-view {
    padding-left: 1rem;
    padding-right: 1rem;

    .logo-image-bg {
      left: -7px;
      width: 150px;
    }

    .heading {
      font-size: 25px;
      line-height: normal;
    }
  }

  .profile {
    .profile-image {
      width: 100%;
    }
  }

  .join-campaign-page {
    .join-campaign-form {
      .locations {
        .location-header {
          .location-header-content {
            .date {
              color: #09aba7;
            }
          }
        }

        .location {
          .location-budget-input-container {
            border: 0;
          }

          .location-budget-box {
            border: 0;
          }
        }

        .creatives {
          .creative-options {
            max-height: 250px;
          }
        }
      }
    }
  }

  .profile {
    .user-brands {
      .brands {
        .brand {
          .subscription-detail {
            border-left: 0;
          }
        }
      }
    }
  }
}

// up to md
@media (max-width: 991.98px) {
  .heading4 {
    line-height: normal;
  }

  .dashboard {
    &.participant-dashboard {
      .campaign-name {
        font-size: 14px;
      }
    }
  }

  .join-campaign-page {
    .join-campaign-form {
      .join-campaign-sub-header {
        .button {
          max-height: 35px;
        }
      }

      .locations {
        .location {
          .location-budget-input-container {
            border: 0;
          }

          .location-budget-box {
            border: 0;
          }
        }
      }
    }
  }

  .join-campaign-form-page {
    // padding-top: 11rem !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .location-subscription {
    .page-description {
      width: 100%;
    }
  }
}

// Learn more page
.ac-faq-card {
  border: 2px solid #f3f3f3;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin-bottom: 8px;

  .MuiIconButton-root {
    color: $primary-color;
  }
}

.obj-camp {
  color: #516068;
  letter-spacing: 0.48px;
}

.MuiAccordion-root:before {
  position: initial !important;
}

.Mui-expanded {
  .faq-question {
    color: $primary-color !important;
    font-weight: $fontWeight-Semi-Bold;
  }
}

.box_lm_1 {
  position: absolute;
  top: 97rem;
  right: 38rem;
}

.box_lm_2 {
  position: absolute;
  top: 78.5rem;
  left: 42.5rem;
}

.hype-name {
  color: $primary-color;
}

.vid-lm-1 {
  position: absolute;
  left: 0.7rem;
  top: -1.7rem;
}

.vid-lm-2 {
  position: absolute;
  right: -4rem;
  bottom: -5.5rem;
  z-index: -1;
}

// How it works
.hiw_name {
  color: #00c4ee;
}

.hiw_btn {
  background-color: $primary-color !important;
}

.hiw_btn .MuiButton-label {
  color: $primary-contrast-color !important;
}

.hiw-heading {
  font-weight: 700 !important;
}

.steps {
  position: relative;
}

.hit_step_content {
  color: #143c4b !important;
  letter-spacing: 0.44px !important;
  font-weight: 500 !important;
}

.box_hit_1 {
  position: absolute;
  right: -7rem;
  z-index: -1;
}

.box_hit_2 {
  position: absolute;
  left: -7rem;
  z-index: -1;
}

.box_hit_3 {
  position: absolute;
  right: -7rem;
  z-index: -1;
}

.how-it-works {
  position: relative !important;
  z-index: 5;
}

.vid-img-1 {
  position: absolute;
  left: -2rem;
  top: -2rem;
}

.vid-img-2 {
  position: absolute;
  right: -4rem;
  top: 17rem;
  z-index: -1;
}

.opt-out-table {
  .table-search {
    justify-content: start !important;
    margin-top: 1rem !important;
  }
}

.upcoming-subscription-renewd {
  background-color: #f2f2f2 !important;
  color: #003044;
  margin-bottom: 5px;
  border-radius: 5px;
}

.upcoming-renewal-locations {
  .subscription-detail {
    font-size: 0.8rem;
  }

  .package {
    color: #989898;
  }

  .upcoming-subscription-renewd {
    box-shadow: none !important;

    .location-name {
      font-size: 1rem;
    }
  }
}

.MuiLink-underlineHover:hover {
  color: #04a999;
}

// resource center section
.resource-center {
  background-color: #f6f8fc;
  border-bottom: 1px solid #ededed;
  margin-top: -58px;

  .subheading {
    color: #516068;
  }

  .MuiTab-root {
    text-transform: capitalize;
    min-width: 271px;
  }
}

.tabs-container {
  // padding-left: 5em;
  // padding-right: 5em;
}

.all-section .MuiTabs-flexContainer {
  display: flex;
  justify-content: center !important;
}

@media (min-width: 1024px) {
  .tabs-container {
    display: flex;
    justify-content: center;
  }
}

.sections {
  .all-section {
    .rc-section {
      .rc-date {
        font-size: 0.7rem;
        color: #516068;
      }

      .player-wrapper {
        .react-player__preview {
          border-radius: 0 !important;
        }

        .MuiDialog-container {
          .MuiDialog-paperWidthSm {
            max-width: 80rem;
          }
        }
      }

      .rc-title {
        text-align: start;
      }
    }
  }
}

.rc-icon {
  width: 20px !important;
  color: #8a8a8b;
  margin-right: 7px;
}

.sg-youtube-img {
  max-width: 100%;
  width: 30px;
  height: 19px;
}

.tab-image {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.selected-image {
  filter: brightness(2) invert(7%) sepia(33%) saturate(4289%) hue-rotate(45deg)
    brightness(73%) contrast(88%);
}

.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #04a999;
}

.title_url {
  color: #003044;
}

.title_url:hover {
  color: #003044;
}

.sg-image {
  min-width: 100%;
  height: 200px;
  object-fit: cover;
}

.MuiPaper-rounded {
  border-radius: 7px !important;
}

.renew-package-card.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.renew-package-card.disabled .package-card {
  background-color: #999999 !important;
  border: 1px solid #999999 !important;
}

.renew-package-card.disabled .package-card .recommended {
  background-color: #ededed33 !important;
}

.renew-package-card.disabled .package-card,
.renew-package-card.disabled .package-card .name,
.renew-package-card.disabled .package-card .total,
.renew-package-card.disabled .package-card .description,
.renew-package-card.disabled .package-card .total-info {
  color: #fff;
}

.disable-button {
  border: 1px solid #bbbbbb;
  background-color: #bbbbbb !important;
  color: #fff !important;
}

//postcard campaigns
.postcard-details {
  .postcard-switch {
    .MuiSwitch-root {
      padding: 6px;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #04a999;
      border-radius: 13px;
      opacity: 1;
    }

    .MuiSwitch-track {
      border-radius: 13px;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
      color: #ffffff;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #04a999;
    }

    .MuiSwitch-track {
      background-color: #c1c1c1;
    }

    .MuiSwitch-switchBase {
      color: #ffffff;
    }
  }

  .mailing-postcards {
    label {
      font-size: 12px;
      color: #1b262c;
      font-weight: 600;
      // position: absolute;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #04a999;
    }

    .MuiSwitch-track {
      background-color: #c1c1c1;
    }

    .MuiSwitch-switchBase {
      color: #ffffff;
    }
  }

  .mailing-postcards {
    label {
      font-size: 12px;
      color: #1b262c;
      font-weight: 600;
    }

    .label {
      position: relative;
    }

    .MuiTypography-body1 {
      font-size: 12px !important;
    }

    .mailing-cost {
      border-left: 1px solid #ccc;
    }

    @media (max-width: 767.98px) {
      .mailing-cost {
        border-left: none;
      }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      .mailing-cost {
        border-left: none;
      }
    }

    .caption-sg {
      font-size: 10px;
    }

    .radio-selections {
      padding: 0px 20px 0px 0px;
      border: 1px solid #d8d8d8;
      border-radius: 12px;
    }

    .MuiSvgIcon-root {
      fill: #dfdfdf;
    }

    .radio-selection-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 7px;
      border: 1px solid #c1c1c1;
    }

    .text-grey {
      color: #c1c1c1;
    }

    .text-white {
      color: white;
    }

    .radio-selection-icon {
      margin-right: 8px;
      padding: 2px;
    }

    .selected-radio {
      background-color: #04a999;
      border: 1px solid #04a999 !important;
    }

    .selected-icon {
      color: white;
      background-color: transparent;
    }

    .MuiFormHelperText-root.Mui-error {
      font-size: 11px;
    }

    .MuiFormControlLabel-label {
      padding-left: 6px;
    }
  }

  .bulk-upload {
    label {
      font-size: 12px;
      color: #1b262c;
      font-weight: 600;
    }

    .MuiTypography-body1 {
      font-size: 12px !important;
    }

    .bulk-cost {
      border-left: 1px solid #ccc;
    }

    @media (max-width: 767.98px) {
      .bulk-cost {
        border-left: none;
      }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      .bulk-cost {
        border-left: none;
      }
    }

    .MuiFormHelperText-root.Mui-error {
      font-size: 11px;
    }

    .MuiFormControlLabel-label {
      padding-left: 6px;
    }
  }

  .Total-cost {
    border-left: 1px solid #ccc;

    label {
      color: #1b262c;
      font-weight: 600;
    }
  }

  @media (max-width: 767.98px) {
    .Total-cost {
      border-left: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .Total-cost {
      border-left: none;
    }
  }

  .scheduled-date {
    label {
      color: #1b262c;
      font-weight: 600;
      font-size: 12px;
      color: #1b262c;
    }
  }
}

.pink-button {
  background-color: #f5239c;
  color: #fff;

  :hover {
    background-color: #f5239c;
    color: #fff;
  }
}

.format-caption {
  color: #c1c1c1;
}

.format {
  color: $primary-color;
}

.upload-template input[type="file"] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  font-size: 11px;
  border-radius: 8px;
  border: 1px solid $primary-color;
}

.upload-template input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: 1px solid #a2a2a2;
  background: #e8e8e8;
  padding: 4px 20px;
  font-size: 9px;
  border-radius: 5px;
  color: #003044;
  cursor: pointer;
  transition: "background .2s ease-in-out";
}

.upload-template input[type="file"]::file-selector-button:hover {
  background: #e8e8e8;
}

.week-picker {
  .MuiFormControl-root {
    background-color: #04a999;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    color: #fff !important;
    font-size: smaller !important;
  }

  .MuiInputBase-root {
    color: #fff !important;
    font-size: small !important;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    color: #fff;
  }

  .MuiInputBase-input {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .MuiPickersDay-daySelected {
    color: #143c4b !important;
    background-color: #adddd9 !important;
  }
}

.error-message {
  color: #ea3a3d !important;
  font-size: 10px;
}

.MuiPickersCalendar-week {
  .MuiPickersDay-daySelected {
    background-color: #adddd9 !important;
    color: #143c4b !important;
  }
}

.color-change .currency {
  color: #143c4b !important;
}

.creative-image-card {
  background-color: #f8f8f8 !important;
  border: 1px solid #c1c1c1 !important;
  border-radius: 10px !important;
}

.address-file {
  .file-name {
    text-decoration: underline !important;
    color: blue !important;
    font-size: 12px;
  }

  .no-file {
    font-size: 12px;
  }
}

.total-amount-align {
  top: 25px;
}

.post-card-campaign-available {
  .time-left {
    .time {
      font-size: 9px !important;
    }
  }

  .list-view-time {
    left: 12px !important;
  }
}

.creative-cerd {
  padding: 5px !important;
}

.advance-settings {
  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    background: transparent;
    // box-shadow: 0px 10px 30px #283f6724;
    border-radius: 10px;
    border: 0;

    .form-group {
      margin-bottom: 0px !important;

      .MuiOutlinedInput-input {
        padding: 10.5px 14px;
      }
    }

    .question-card {
      padding: 11px;
      margin-bottom: 14px;
      border: 2px solid #e1e1e1;
      border-radius: 8px;

      .file-name {
        color: #009688;
      }

      .question_title {
        color: #a1a1a1;
      }
    }
  }
}

.archived-campaigns {
  .card-footer {
    .learn-more {
      height: 33px;
    }
  }
}

.btn-download {
  height: 31px;
  font-size: smaller;
  align-items: center;
  width: 156px !important;
}

// left-nav feature
.MuiDrawer-root {
  .MuiDrawer-paper {
    // top: 60px;
    z-index: 0;
  }
}

.side-nav {
  .MuiTypography-body1 {
    font-size: 0.7rem;
  }

  .user-role {
    .MuiTypography-body1 {
      color: #acacac;
      font-weight: 600;
    }
  }

  .MuiListItemIcon-root {
    min-width: 33px;
  }

  .MuiListItemText-primary {
    font-weight: 600;
  }

  .ei {
    font-size: 19px;
  }

  .el-icon {
    color: $primary-color;
  }

  .MuiList-padding {
    padding-top: 60px;
  }
}

.profile-link {
  text-decoration: underline !important;
  color: #1e8fff;
  font-size: 8px;
}

.dropdown-menu .app-footer {
  margin-bottom: -24px;
}

.resources {
  padding-top: 2rem;

  .container-fluid {
    padding: 0;
  }

  .resource-section {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.join-page {
  .page {
    padding-top: 0px !important;
  }
}

.nav-sections {
  margin-left: -7px;
}

.profile-links {
  .profile-link {
    color: #1e8fff !important;
  }
}

// .join-campaign-page {
//   padding-left: 15px;
//   padding-right: 15px;
// }

@media (max-width: 768px) {
  .large-screen-only {
    display: none;
  }
}

@media (min-width: 769px) {
  .large-screen-only {
    display: inline;
  }
}

.text-light-grey {
  color: #a098ae;
}

.text-dark-grey {
  color: #495a69;
}

.MuiAutocomplete-popper {
  z-index: 1 !important;
}

@import "./assets/css/animations.scss";
@import "./assets/css/postcardCampaigns.scss";
@import "./assets/css/location-subscription.scss";
@import "./assets/css/drillUpDown.scss";
@import "./assets/css/userFeedback.scss";
@import "./assets/css/customize-ads.scss";
@import "./assets/css/campaign-confirm-thankyou-page.scss";
@import "./assets/css/brand-request.scss";
@import "./assets/css/boost-campaign.scss";
@import "./assets/css/campaign-pause.scss";
@import "./assets/css/location-list-modal.scss";
