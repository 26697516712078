.rp-custom-month-range-picker-popup {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
    background: #ebeeed;
    border-radius: 5px;
  }

  .months {
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 33.3%;
      }
    }

    .month {
      padding: 0.5rem 0;
      text-align: center;
      // border: 1px solid #eee;
      border-radius: 3px;
      margin-bottom: 3px;
      font-size: 14px;
      font-weight: 700;
      transition: all 0.05s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: lighten(#09aba7, 60%);
      }

      &.in-range {
        background-color: lighten(#09aba7, 55%);
        border-radius: 0;
      }

      &.selected {
        background-color: #09aba7;
        color: #fff;
        border-radius: 3px;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.label {
  font-weight: 600 !important;
  margin-bottom: 3px;
}

.month-picker-button {
  .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
