.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @media (max-width: 1024px) {
    min-height: 100vh;
    height: auto;
  }

  .inner {
    margin: 2rem auto;
    text-align: center;

    .heading {
      font-size: clamp(
        28px,
        5vw,
        45px
      ); // Minimum 28px, scales with viewport, max 45px
      margin: 3rem auto 1rem;
      font-weight: 700;
      color: #003044;
    }

    .description {
      font-size: clamp(16px, 3vw, 22px); // Responsive font size for description
      color: #003044;
      // margin: 0 auto; // Center description text
    }

    .img-box {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        object-fit: contain;
      }
    }
  }
}
