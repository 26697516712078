.card-shadow {
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.0862745098) !important;
}

@mixin generate-min-width-classes($min, $max) {
  @for $i from $min to $max {
    .min-w-px-#{$i} {
      min-width: #{$i}px;
    }
  }
}

// Call the mixin with the desired range
@include generate-min-width-classes(1, 500);
