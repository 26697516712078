.drill-button {
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.drill-button:hover {
  background-color: #059b8d;
}

.drill-button svg {
  fill: white;
  font-size: 15px;
}

.drill-button-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

.drill-button.disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.mui-date-picker {
  .MuiTextField-root {
    .MuiIconButton-root {
      color: white;
      padding: 5px;
    }
  }
}

.mui-loader {
  right: 1rem;
}
