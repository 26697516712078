.brand-request-details {
  .field {
    margin: 8px auto;
  }
  .label {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 700 !important;
  }
  .answer {
    font-size: 14px;
  }
}
